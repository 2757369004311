import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import ProductIcon from "../../assets/productIcon.svg";
import { Table, Skeleton, Col, Empty } from "antd";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";

function ProductOverProfile() {
  const { id } = useParams();
  const [productName, setProductName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [TableLoading, setTableLoading] = useState(false);

  const getProduct = async () => {
    setTableLoading(true);
    let path = `/redshiftDataManager/getSpecificCompanyDetails?companyid=${id}`;
    await API.get("XaaSPricingREST", path)
      .then((res) => {
        let tempKey = Object.keys(res?.products);
        let data = Object.keys(res?.products);

        let temp = [];
        Object.values(res?.products[tempKey[0]]).map((item) => {
          if (item !== null) {
            Object.entries(item).map(([key, value]) => {
              if (value?.["product edition description"]) {
                temp.push({
                  "product edition description":
                    value?.["product edition description"].split("-"),
                  edition: key,
                });
                temp.sort((a, b) => a.edition.localeCompare(b.edition));
              }
              return temp;
            });
          }
          return temp;
        });
        setTableData(temp);
        setProductName(data[0]);
        setTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Product Edition",
      dataIndex: "edition",
      key: "edition",
      width: "18%",
      render: (record) => {
        return <span className="data-size">{record}</span>;
      },
    },
    {
      title: "Product Edition Description",
      dataIndex: "product edition description",
      key: "description",
      render: (record, text) => {
        return record.map((item) => {
          if (item !== "" && item !== undefined && item.length > 1) {
            return (
              <>
                <span className="data-size">- {item}</span>
                <br />
              </>
            );
          }
        });
      },
    },
  ];

  return (
    <div className="main-product-overview-wrapper">
      {TableLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
          <Skeleton active paragraph={{ rows: 7 }} />{" "}
        </div>
      ) : tableData?.length > 0 ? (
        <>
          <div className="col-icon">
            <div>
              <img src={ProductIcon} alt="" />
            </div>
            <div className="col-text">
              <span className="text-wrapper-title">{productName}</span>
            </div>
          </div>
          <div className="table-wrapper">
            <Table
              className="table-css"
              bordered
              dataSource={tableData}
              columns={columns}
              pagination={false}
            ></Table>
          </div>{" "}
        </>
      ) : (
        <Col span={24}>
          <Empty className="empty-data" />
        </Col>
      )}
    </div>
  );
}

export default ProductOverProfile;
