import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Button,
  message,
  Tooltip,
  Empty,
  Input,
  Skeleton,
  Card,
} from "antd";
import {
  EditOutlined,
  CheckOutlined,
  InboxOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { listDashboardItemss } from "../../graphql/queries";
import { updateDashboardItems } from "../../graphql/mutations";
import "./dashboard.css";
import downloadIcon from "../../assets/download.svg";
import Modal from "antd/lib/modal/Modal";
import Dragger from "antd/lib/upload/Dragger";
import {
  createReport,
  deleteResearchReport,
  fetchReports,
} from "../../store/action/orphanAction";
import { themeColors } from "../../themeColors";
import Blogs from "./Blogs";
import WebsiteGrader from "./WebsiteGrader";
import TextArea from "antd/lib/input/TextArea";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

function MyDashboard() {
  const { Meta } = Card;

  const ref = useRef(null);
  const selectDropDown = useSelector((state) => state.loginReducer);
  const [loader, setLoader] = useState(false);
  const [description, setDescription] = useState();
  const [iconShow, setIconShow] = useState(false);
  const { groups: account, groups } = useSelector(
    (state) => state.loginReducer
  );
  const history = useHistory();
  // UPLOAD REPORT STUFF START
  const [uploadReportModal, setuploadReportModal] = useState(false);
  const [filesToUpload, setfilesToUpload] = useState(null);
  const [selectedFiles, setselectedFiles] = useState([]);

  //DELETE REPORT
  const [deleteReportModal, setdeleteReportModal] = useState(false);
  const [reportToDelete, setreportToDelete] = useState({});

  //for fetching dasboard description items

  const { accountDetails } = useSelector((state) => state.loginReducer);

  const getDashboardItems = async () => {
    await API.graphql(graphqlOperation(listDashboardItemss))
      .then((res) => {
        let data = res?.data?.listDashboardItemss?.items?.[0];
        setDescription(data);
      })
      .catch((err) => console.error(err));
  };

  //for updating dashboard description items
  const updateDashboard = async () => {
    await API.graphql(
      graphqlOperation(updateDashboardItems, {
        input: {
          id: description?.id,
          description: description?.description,
        },
      })
    )
      .then((res) => {
        message.success("Dashboard Item updated successfully");
        setIconShow(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const dispatch = useDispatch();

  //handle change function for dashboard description
  const handleChange = (e) => {
    setDescription({ ...description, description: e.target.value });
    setIconShow(true);
  };

  //quicksight graph stuff
  const dashboardRef = useRef();
  function dashboardloadCallback(e) {
    setLoader(false);
  }

  function dashboardErrorCallback(e) {
    setLoader(false);
  }

  const embedDashboardChart = async (id) => {
    let apiURL = `/getEmbedUrl/getMyDashboard?account=${accountDetails?.id}`;
    let res = await API.get("XaaSPricingREST", apiURL);
    let iframeData = document.getElementById("iframecontainer");
    iframeData.innerHTML = "";
    const options = {
      url: res?.EmbedUrl,
      container: document.getElementById("iframecontainer"),
      scrolling: "yes",
      width: "100%",
      locale: "en-US",
      footerPaddingEnabled: true,
      undoRedoDisabled: true,
      resetDisabled: true,
      loadCallback: dashboardloadCallback,
      errorCallback: dashboardErrorCallback,
    };
    dashboardRef?.current && QuickSightEmbedding.embedDashboard(options);
    setLoader(false);
  };

  const embedFilterChart = async (id) => {
    let apiURL = `/getEmbedUrl/getMyDashboard?account=${accountDetails?.id}`;
    let res = await API.get("XaaSPricingREST", apiURL);
    const options = {
      url: res?.EmbedUrl,
      container: document.getElementById("iframechart"),
      scrolling: "yes",
      width: "100%",
      locale: "en-US",
      id: "iframe1",
      footerPaddingEnabled: true,
      undoRedoDisabled: true,
      resetDisabled: true,
      parameters: {
        xaascategory: selectDropDown?.selectDropDown,
      },
      loadCallback: dashboardloadCallback,
      errorCallback: dashboardErrorCallback,
    };
    dashboardRef?.current && QuickSightEmbedding.embedDashboard(options);
    setLoader(false);
  };

  const [ResearchReport, setResearchReport] = useState([]);
  useEffect(() => {
    if (dashboardRef || groups.includes("admin")) {
      embedDashboardChart();
      embedFilterChart();
      getDashboardItems();
      HandleFetchReports();
    }
    // eslint-disable-next-line
  }, []);

  const HandleFetchReports = async () => {
    setLoader(true);
    let reports = await dispatch(fetchReports());

    Promise.all(
      reports.map((item) => {
        return Storage.get(item.key, {
          level: "public",
        });
      })
    )
      .then((res) => {
        res.forEach((item, index) => {
          reports[index].downloadURL = item;
        });
        setResearchReport(reports);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        message.error("Unable to get reports");
      });
  };

  const text = <span>Please edit here</span>;

  const handleClick = () => {
    ref.current.focus();
  };

  const [selectedFileName, setselectedFileName] = useState("");
  const props = {
    name: "file",
    multiple: false,
    accept: "application/pdf,application/vnd.ms-excel",

    onChange(info) {
      if (info.fileList?.length === 0) {
        setfilesToUpload(null);
      } else {
        setfilesToUpload(info?.file);
        setselectedFileName(info?.file?.name);
      }

      if (info.fileList?.length >= selectedFiles?.length) {
        setselectedFiles((old) => {
          return [...old, info?.file];
        });
      } else {
        let fileindex = selectedFiles.findIndex(
          (item) => item.name === info.file.name
        );
        setselectedFiles((old) => {
          old = old.splice(fileindex, 1);
          return old;
        });
      }
    },

    beforeUpload: () => {
      return false;
    },
  };

  const handleFileUpload = () => {
    setLoader(true);
    return Storage.put(filesToUpload?.name, filesToUpload, {
      level: "public",
      contentType: filesToUpload.type,
    })
      .then((res) => {
        UploadKeysInDatabase(res);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const onModalClose = () => {
    setuploadReportModal(false);
    setfilesToUpload(null);
    setselectedFileName("");
    setLoader(false);
    setselectedFiles([]);
  };

  const UploadKeysInDatabase = (input) => {
    let inputData = [{ key: input?.key, name: selectedFileName }];
    Promise.all(inputData?.map((item) => dispatch(createReport(item))))
      .then((_) => {
        HandleFetchReports();
        onModalClose();
      })
      .catch((err) => {
        message.error("Something went wrong");
        setLoader(false);
      });
  };

  const handleDeleteReport = () => {
    setLoader(true);
    let inputdata = {
      id: reportToDelete?.id,
    };
    dispatch(deleteResearchReport(inputdata))
      .then(async (res) => {
        await Storage.remove(reportToDelete?.key, { level: "protected" });
        HandleFetchReports();
        setreportToDelete({});
        message.success("Report deleted");
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        message.error("Something went wrong");
      });
  };

  const handleClickPdf = (e) => {
    e.preventDefault();
    window.location.href = e;
  };

  useEffect(() => {
    if (Object.keys(reportToDelete)?.length > 0) {
      setdeleteReportModal(true);
    } else {
      setdeleteReportModal(false);
    }
  }, [reportToDelete]);

  return (
    <>
      <div>
        <div
          style={{
            width: "100%",
            background: "white",
            borderRadius: "8px",
            marginBottom: "10px",
          }}
        >
          <div className="DataBox" style={{ marginTop: 10 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p
                style={{
                  fontSize: "18px",
                  margin: 5,
                  fontWeight: 700,
                  fontFamily: "Matter-bold",
                }}
              >
                <b>XaaS Pricing Analyst Insights</b>
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  className="about-graphs"
                  onClick={() =>
                    history.push({
                      pathname: "/graph-definition",
                      state: { dashboardData: "true" },
                    })
                  }
                >
                  Graph Definitions
                </span>
                <span
                  style={{
                    fontStyle: "italic",
                    color: "#416B88",
                    fontSize: "14px",
                    fontWeight: 600,
                    marginRight: 10,
                    fontFamily: "Matter-bold",
                  }}
                >
                  <InfoCircleOutlined />
                </span>
              </div>
            </div>

            {loader && <div className="loading">Loading;</div> ? (
              <Skeleton.Input active size="large" style={{ width: "140vh" }} />
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextArea
                  value={description?.description}
                  style={{
                    border: "none",
                    fontSize: 15,
                    color: "black",
                    background: "transparent",
                    cursor: !groups.includes("admin") ? "default" : "",
                  }}
                  onChange={handleChange}
                  ref={ref}
                  autoSize
                  disabled={!groups.includes("admin")}
                />

                {groups.includes("admin") ? (
                  iconShow === true ? (
                    <Button
                      style={{ marginLeft: 10, border: "none" }}
                      onClick={updateDashboard}
                      icon={
                        <CheckOutlined
                          style={{ cursor: "pointer", color: "green" }}
                        />
                      }
                    ></Button>
                  ) : (
                    <Tooltip title={text}>
                      <Button
                        onClick={handleClick}
                        style={{ marginLeft: 10, border: "none" }}
                        icon={
                          <EditOutlined
                            style={{ cursor: "pointer", color: "green" }}
                          />
                        }
                      ></Button>
                    </Tooltip>
                  )
                ) : null}
              </div>
            )}

            <div
              data-tour="tour__insight"
              ref={dashboardRef}
              id="iframechart"
              className="iframe "
              style={{
                width: "100%",
                height: "780px",
                marginTop: 10,
                position: "relative",
              }}
            >
              <span class="skeleton-square1" style={{ width: "49%" }}></span>
              <span class="skeleton-square2" style={{ width: "49%" }}></span>
              <span class="skeleton-box" style={{ width: "100%" }}></span>
            </div>

            <div>
              <div style={{ padding: 20, textAlign: "center" }}>
                {loader && <div className="loading">Loading;</div> ? (
                  <Row>
                    <Col span={24}>
                      <Skeleton.Input
                        active
                        style={{ borderRadius: "7px", height: "35px" }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Button
                    size="large"
                    style={{
                      color: "#416B88",
                      borderColor: "#416B88",
                      fontWeight: "bold",
                      fontSize: 16,
                      height: "auto",
                      padding: "7px 25px",
                    }}
                    className="analytics-btn"
                    onClick={() => history.push("/analytics/packaging-models")}
                  >
                    View All Analytics
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="DataBox" style={{ marginTop: 10 }}>
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={5}
            >
              <Row justify="space-between" style={{ marginBottom: 10 }}>
                <Col>
                  <span
                    style={{
                      fontWeight: "700px",
                      fontSize: 18,
                      fontFamily: "Matter-bold",
                    }}
                  >
                    Research Reports
                  </span>
                </Col>
                <Col>
                  {loader && <div className="loading">Loading;</div> ? (
                    <Row>
                      <Col span={24}>
                        <Skeleton.Input
                          active
                          style={{ borderRadius: "7px", height: "35px" }}
                        />
                      </Col>
                    </Row>
                  ) : (
                    <>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ButtonBack
                          style={{ border: "none", background: "white" }}
                        >
                          {" "}
                          <ArrowLeftOutlined
                            style={{
                              fontSize: 16,
                              color: "#416B88",
                              strokeWidth: 50,
                              stroke: "#416B88",
                            }}
                            className="carousel-btn-icon"
                          />
                        </ButtonBack>
                        <ButtonNext
                          disabled={ResearchReport?.length < 4}
                          style={{ border: "none", background: "white" }}
                        >
                          {" "}
                          <ArrowRightOutlined
                            style={{
                              fontSize: 16,
                              color: "#416B88",
                              strokeWidth: 50,
                              stroke: "#416B88",
                            }}
                            className="carousel-btn-icon"
                          />
                        </ButtonNext>
                        {account?.includes("admin") && (
                          <Button
                            style={{
                              color: "#416B88",
                              borderColor: "#416B88",
                              fontWeight: 600,
                              fontSize: 16,
                              height: "auto",
                              padding: "6px 25px",
                              marginLeft: 20,
                            }}
                            onClick={() => setuploadReportModal(true)}
                          >
                            Upload PDFs
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <Row gutter={16}>
                {loader && <div className="loading">Loading;</div> ? (
                  <Skeleton active paragraph={{ rows: 5 }} />
                ) : (
                  <>
                    {ResearchReport?.length > 0 ? (
                      <>
                        <Slider>
                          {ResearchReport?.map((doc, key) => {
                            return (
                              <>
                                <Col span={6} key={key}>
                                  <div>
                                    <Slide index={key}>
                                      
                                      <Card
                                        className="card-report"
                                        hoverable
                                        style={{
                                          width: 270,
                                          overflow: "hidden",
                                          boxShadow:
                                            "6px 6px 6px rgba(0, 0, 0, 0.15)",
                                          borderRadius: 11,
                                          border: "4px solid #EFF4F9",
                                          

                                        }}
                                        cover={
                                          <a
                                            href={doc?.downloadURL}
                                            target="_blank"
                                            rel="noreferrer"
                                            onClick={() =>
                                              handleClickPdf(doc?.downloadURL)
                                            }
                                            style={{marginBottom: "-15px"
                                            }}
                                          >
                                            <div>
                                              <object
                                                width="100%"
                                                height="200"
                                                data={doc?.downloadURL}
                                                style={{
                                                  pointerEvents: "none",
                                                }}
                                                type="application/pdf"
                                              >
                                                {" "}
                                              </object>
                                            </div>
                                          </a>
                                        }
                                      >
                                        <Meta
                                          title={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                              }}
                                            >
                                              <Row justify="space-between">
                                                <Col span={15}>
                                                  <span
                                                    style={{
                                                      wordBreak: "break-all",
                                                    }}
                                                  >
                                                    {doc?.name}
                                                  </span>
                                                </Col>
                                                <Col span={6}>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "flex-end",
                                                      height: "100%",
                                                    }}
                                                  >
                                                    <a
                                                      href={doc?.downloadURL}
                                                      download
                                                      target={"_blank"}
                                                      rel="noreferrer"
                                                    >
                                                      <img
                                                        src={downloadIcon}
                                                        alt=""
                                                      />
                                                    </a>
                                                    {groups.includes(
                                                      "admin"
                                                    ) && (
                                                      <DeleteOutlined
                                                        onClick={() => {
                                                          setreportToDelete(
                                                            doc
                                                          );
                                                        }}
                                                        style={{
                                                          fontSize: 22,
                                                          color:
                                                            themeColors?.primary,
                                                          paddingLeft: 10,
                                                          cursor: "pointer",
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          }
                                        />
                                      </Card>
                                    </Slide>
                                  </div>
                                </Col>
                              </>
                            );
                          })}
                        </Slider>
                      </>
                    ) : (
                      <Col span={24}>
                        <Empty description="No report available" />
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </CarouselProvider>
          </div>
        </div>

        <div>
          <div className="DataBox" style={{ marginTop: 10 }}>
            <WebsiteGrader history={history} />
          </div>
        </div>
        <div>
          <div className="DataBox" style={{ marginTop: 10 }}>
            <Blogs />
          </div>
        </div>
      </div>

      {uploadReportModal && (
        <Modal
          className="header-style"
          title={
            <span style={{ fontWeight: 600, fontSize: 18 }}>
              Upload Research Reports
            </span>
          }
          visible={uploadReportModal}
          footer={null}
          onCancel={onModalClose}
        >
          <div style={{ marginBottom: 20, width: 300 }}>
            <label style={{ fontSize: 16 }}>Report Name</label>
            <Input
              value={selectedFileName}
              style={{ height: 35, fontSize: 16 }}
              onChange={(e) => {
                setselectedFileName(e.target.value);
              }}
              placeholder="Report name (select file to autofill)"
            />
          </div>
          <Tooltip
            title={
              selectedFiles?.length >= 1 ? "can not upload multiple files" : ""
            }
          >
            <Dragger {...props} disabled={selectedFiles?.length >= 1}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text" style={{ fontSize: 16 }}>
                Click or drag file to this area to upload
              </p>
            </Dragger>
          </Tooltip>

          <Row justify="center">
            <Button
              type="primary"
              onClick={handleFileUpload}
              disabled={selectedFiles?.length !== 1}
              loading={loader}
              style={{
                borderColor: "#416B88",
                background: "#416B88",
                color: "white",
                fontWeight: "bold",
                marginTop: 16,
                height: "auto",
                padding: "7px 25px",
                fontSize: 16,
              }}
            >
              Upload
            </Button>
          </Row>
        </Modal>
      )}

      <Modal
        className="header-style"
        style={{ fontWeight: 600, fontSize: 18 }}
        title={
          <span style={{ fontSize: 18, fontWeight: 600 }}>Delete Report</span>
        }
        visible={deleteReportModal}
        onOk={handleDeleteReport}
        footer={null}
        onCancel={() => setreportToDelete({})}
      >
        <Row justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <Typography.Text style={{ fontSize: 16 }}>
              Are you sure you want to delete{" "}
              <span style={{ color: themeColors.primary }}>
                {reportToDelete?.name}
              </span>{" "}
              Report?
            </Typography.Text>
          </Col>

          <Col>
            <Button
              type="secondary"
              onClick={() => setreportToDelete({})}
              style={{
                marginTop: 16,
                borderColor: "black",
                padding: "4px 14px",
                fontSize: 16,
                fontWeight: 600,
                height: "auto",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeleteReport}
              className="delete-btn"
              loading={loader}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default MyDashboard;
