import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import { Row, Col, Divider, Tabs, Skeleton, Tooltip } from "antd";
import GroupIcon from "../../assets/GroupIcon.svg";
import Website from "../../assets/website.svg";
import HeadQuarter from "../../assets/headquarter.svg";
import Employee from "../../assets/employee.svg";
import YearIcon from "../../assets/year.svg";
import PuzzleIcon from "../../assets/puzzle.svg";
import PackagingModelProfile from "./PackagingModelProfile";
import ProductOverProfile from "./ProductOverProfile";
import PricingmodelProfile from "./PricingmodelProfile";
import PricingDiscountingModelProfile from "./PricingDiscountingModelProfile";
import { API } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { DoubleLeftOutlined } from "@ant-design/icons";

function CompanyProfile() {
  const { TabPane } = Tabs;
  const { id } = useParams();
  const history = useHistory();
  const [companyData, setCompanyData] = useState("");
  const [TableLoading, setTableLoading] = useState(false);
  const getProduct = async () => {
    setTableLoading(true);
    let path = `/redshiftDataManager/getSpecificCompanyDetails?companyid=${id}`;
    await API.get("XaaSPricingREST", path)
      .then((res) => {
        setCompanyData(res);
        setTableLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      {TableLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
          <Skeleton.Input style={{ height: "20vh" }} active block />{" "}
        </div>
      ) : (
        <div className="main-wrapper">
          <Row>
            <Col span={5}>
              <Tooltip placement="topLeft" title="Go Back">
                <DoubleLeftOutlined
                  onClick={() => history.push("/company-preview")}
                  style={{
                    fontSize: 19,
                    color: "#416B88",
                    strokeWidth: 50,
                    stroke: "#416B88",
                    margin: "10px 0px 0px 10px",
                  }}
                />
              </Tooltip>
              <div className="text-wrapper">
                <div style={{ textAlign: "center" }}>
                  <img src={GroupIcon} alt="" />
                </div>
                <div>
                  <span className="span-text">{companyData?.company}</span>
                </div>
              </div>
            </Col>
            <Col>
              <div className="my-plan-vertical-divider">
                <Divider
                  type="vertical"
                  style={{ border: "1px solid #CAD8E5", height: "100%" }}
                />
              </div>
            </Col>
            <Col span={18}>
              <Row>
                <Col span={12}>
                  <Row className="col1-wrapper">
                    <Col span={10}>
                      <div className="website-wrapper">
                        <img src={Website} alt="" />
                        <span className="col-texr-wrapper">Website</span>
                      </div>
                    </Col>
                    <Col span={14}>
                      <div className="website-wrapper">
                        <span
                          className="col-texr-wrapper mr"
                          style={{ color: "#416B88" }}
                        >
                          <span>:</span>
                          {companyData?.website ? companyData?.website : "---"}
                        </span>
                      </div>
                    </Col>
                    <Col span={10}>
                      <div className="website-wrapper">
                        <img src={HeadQuarter} alt="" />
                        <span className="col-texr-wrapper">Headquarters</span>
                      </div>
                    </Col>
                    <Col span={14}>
                      <div className="website-wrapper">
                        <span
                          className="col-texr-wrapper mr"
                          style={{ color: "#416B88" }}
                        >
                          <span>:</span>
                          {companyData?.region ? companyData?.region : "---"}
                        </span>
                      </div>
                    </Col>
                    <Col span={10}>
                      <div className="website-wrapper">
                        <img src={Employee} alt="" />
                        <span className="col-texr-wrapper">
                          Total Employees
                        </span>
                      </div>
                    </Col>
                    <Col span={14}>
                      <div className="website-wrapper">
                        <span
                          className="col-texr-wrapper mr"
                          style={{ color: "#416B88" }}
                        >
                          <span>:</span>
                          {companyData?.["total employees"]
                            ? companyData?.["total employees"]
                            : "---"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col span={11}>
                  <Row className="col1-wrapper">
                    <Col span={14}>
                      <div className="website-wrapper">
                        <img src={YearIcon} alt="" />
                        <span className="col-texr-wrapper">Year Founded</span>
                      </div>
                    </Col>
                    <Col span={10}>
                      <div className="website-wrapper">
                        <span
                          className="col-texr-wrapper mr"
                          style={{ color: "#416B88" }}
                        >
                          <span>:</span>
                          {companyData?.["year founded"]
                            ? companyData?.["year founded"]
                            : "---"}
                        </span>
                      </div>
                    </Col>
                    <Col span={14}>
                      <div className="website-wrapper">
                        <img src={PuzzleIcon} alt="" />
                        <span className="col-texr-wrapper">Xaas Category</span>
                      </div>
                    </Col>
                    <Col span={10}>
                      <div className="website-wrapper">
                        <span
                          className="col-texr-wrapper mr"
                          style={{ color: "#416B88", lineHeight: 1.5 }}
                        >
                          <span>:</span>
                          {companyData?.["xaas_category_5"]
                            ? companyData?.["xaas_category_5"]
                            : "---"}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="update-date">
                <span className="date-wrapper">
                  Date Added:{" "}
                  {moment(companyData?.["initial date of entry"]).format("LL")}
                </span>
                <span
                  style={{ marginLeft: 12, marginRight: 20 }}
                  className="date-wrapper"
                >
                  Last Updated:{" "}
                  {moment(companyData?.["date of last update"]).format("LL")}
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div className="tab-wrapper">
        <Tabs className="tabfont profile-tab" centered>
          <TabPane
            tab={
              <h3
                className="font-family-style"
                style={{ marginBottom: 0, fontWeight: 600, fontSize: 18 }}
              >
                Products Overview
              </h3>
            }
            key="1"
          >
            <ProductOverProfile />
          </TabPane>
          <TabPane
            tab={
              <h3
                className="font-family-style"
                style={{ marginBottom: 0, fontWeight: 600, fontSize: 18 }}
              >
                Packaging Models
              </h3>
            }
            key="2"
          >
            <PackagingModelProfile />
          </TabPane>
          <TabPane
            tab={
              <h3
                className="font-family-style"
                style={{ marginBottom: 0, fontWeight: 600, fontSize: 18 }}
              >
                Pricing Models
              </h3>
            }
            key="3"
          >
            <PricingmodelProfile />
          </TabPane>
          <TabPane
            tab={
              <h3
                className="font-family-style"
                style={{ marginBottom: 0, fontWeight: 600, fontSize: 18 }}
              >
                Pricing & Discounting
              </h3>
            }
            key="4"
          >
            <PricingDiscountingModelProfile />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}

export default CompanyProfile;
