import {
  Form,
  Select,
  Button,
  Col,
  Row,
  Input,
  Table,
  Modal,
  Typography,
  message,
  Skeleton,
} from "antd";
import React, { useState, useEffect } from "react";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import InviteModalIcon from "../../assets/InviteModalIcon.svg";
import { emailPattern } from "../../utility/constants";
import Moment from "react-moment";
import sdk from "../../sdk/Accounts";
import './admin.css'

const Users = (props) => {
  const [Usersdata, setUsersdata] = useState([]);
  const [tableloading, settableloading] = useState(false);
  const [SearchParams, setSearchParams] = useState({});
  const [searchOption, setsearchOption] = useState("Email");
  const [userbackup, setuserbackup] = useState([]);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const handleCancel = () => {
    setIsInviteModalOpen(false);
  };

  const get_attribute = (user, key) => {
    let result = user.filter((attribute) => attribute.Name === key);
    if (result.length > 0) {
      return result[0]["Value"];
    } else {
      return "";
    }
  };
  const columns = [
    {
      title: <Typography.Text className="font-weight">Name</Typography.Text>,
      width: "10%",
      height: "60px",
      dataIndex: "Attributes",
      key: "Name",
      render: (name) => (
        <>
          {get_attribute(name, "given_name") ? (
            <span style={{ fontSize: 16 }}>
              {get_attribute(name, "given_name") +
                " " +
                get_attribute(name, "family_name")}
            </span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      sorter: (a, b) => {
        var namea = a.Attributes[2].Value + " " + a.Attributes[3].Value;
        var nameb = b.Attributes[2].Value + " " + b.Attributes[3].Value;
        return namea.localeCompare(nameb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: <Typography.Text className="font-weight">Email</Typography.Text>,
      width: "20%",
      dataIndex: "Attributes",
      key: "Email",
      render: (attr) => (
        <span style={{ fontSize: 16 }}>{get_attribute(attr, "email")}</span>
      ),
      sorter: (a, b) => {
        var emaila = a.Attributes[4].Value;
        var emailb = b.Attributes[4].Value;
        return String(emaila).localeCompare(emailb);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: (
        <Typography.Text className="font-weight">
          Onboarding Status
        </Typography.Text>
      ),
      width: "15%",
      dataIndex: "UserStatus",
      key: "user_status",
      render: (status) => <span style={{ fontSize: 16 }}>{status}</span>,
    },
    {
      title: <Typography.Text className="font-weight">Company</Typography.Text>,
      width: "15%",
      dataIndex: "Company_Data",
      key: "Company_Name",
      render: (name, record) => (
        <span
          style={{ fontSize: 16 }}
          title="Click here to select company for admin view"
          className="company-name"
          onClick={() => props.selectCompany(record, "user")}
        >
          {name?.company ? name?.company : "-"}
        </span>
      ),
      sorter: (a, b) => {
        return a?.Company_Data?.company.localeCompare(b?.Company_Data?.company);
      },
      sortDirections: ["ascend", "descend", "ascend"],
      showSorterTooltip: false,
    },
    {
      title: (
        <Typography.Text className="font-weight">Last Login</Typography.Text>
      ),
      width: "18%",
      dataIndex: "last_login",
      key: "last_modified",
      render: (modifyDate) => (
        <span style={{ fontSize: 16 }}>
          {modifyDate !== '-' ? 
          <Moment date={modifyDate} format="lll" /> :
          "-"
          }
        </span>
      ),
      sorter: (a, b) => {
        return String(a.last_login).localeCompare(b.last_login) ? (
          a.last_login).localeCompare(b.last_login
        ): "-";
      },
      defaultSortOrder: "descend",
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: (
        <Typography.Text className="font-weight">Date of Sign Up</Typography.Text>
      ),
      width: "18%",
      dataIndex: "UserCreateDate",
      key: "userCreate_Date",
      render: (modifyDate) => (
        <span style={{ fontSize: 16 }}>
          <Moment date={modifyDate} format="lll" />
        </span>
      ),
      sorter: (a, b) => {
        return String(a.UserCreateDate).localeCompare(
          b.UserCreateDate
        );
      },
      defaultSortOrder: "descend",
      showSorterTooltip: false,
      sortDirections: ["ascend", "descend", "ascend"],
    },
  ];

  // Function to fetch user's data
  const getUserData = async () => {
    let nextToken = null;
    settableloading(true);
    setUsersdata([]);
    setuserbackup([]);
    do {
      let response = await sdk.getAllUsers(nextToken);
      let result = response?.data?.listAccounts?.items;
      let usersdata = [];
      // eslint-disable-next-line array-callback-return
      result?.map((item) => {
        // eslint-disable-next-line array-callback-return
        item?.users?.map((user) => {
          let data = JSON.parse(user);
          let userData = JSON.parse(item?.users?.[0]);
          data["last_login"] =
            userData?.Attributes?.[3]?.Name === "custom:last_login"
              ? userData?.Attributes?.[3]?.Value
              : "-";
          // delete item?.users;
          data["Company_Data"] = item;
          usersdata.push(data);
        });
      });
      setUsersdata((old) => old.concat(usersdata));
      setuserbackup((old) => old.concat(usersdata));
      nextToken = response?.data?.listAccounts?.nextToken;
    } while (nextToken);
    settableloading(false);
  };

  const handle_search = (value) => {
    setUsersdata(userbackup);
    setSearchParams({
      Name: value["search_type"],
      Value: value["search_value"] ? value["search_value"].toLowerCase() : "",
    });
  };

  const searchUser = (SearchParams) => {
    if (SearchParams.Name === "First name") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(get_attribute(user.Attributes, "given_name"))
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    } else if (SearchParams.Name === "Last name") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(get_attribute(user.Attributes, "family_name"))
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    } else if (SearchParams.Name === "Email") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(get_attribute(user.Attributes, "email"))
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    } else if (SearchParams.Name === "Company") {
      setUsersdata(
        Usersdata.filter((user) =>
          String(user["Company_Data"]?.company)
            .toLowerCase()
            .includes(SearchParams.Value.toLowerCase().trim())
        )
      );
    }
  };

  // Function to check email addresses
  const validateEmail = (e) => {
    const email_pattern = emailPattern;
    if (e.length > 0) {
      const found = e[e.length - 1].match(email_pattern);
      if (!found) {
        e.pop();
        message.error("Please enter valid email");
      } else {
        e[e.length - 1] = e[e.length - 1].toLowerCase();
      }
    }
  };

  useEffect(() => {
    searchUser(SearchParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SearchParams]);

  useEffect(() => {
    getUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <Row>
          <Col span={8}></Col>
          <Col span={10} lg={10} md={10} sm={24} xs={24}>
            <Form
              name="search_users"
              onFinish={handle_search}
              initialValues={{ search_type: "Email" }}
              layout="vertical"
              className="user-form"
            >
              <Form.Item
                name="search_type"
                label={<span style={{ fontSize: 18,fontWeight: 600 }}>Select Filter</span>}
                style={{ marginRight: "10px" }}
              >
                <Select
                  style={{ width: "125px", fontSize: 18, height: 44 }}
                  size="large"
                  onChange={(e) => setsearchOption(e)}
                  className="dropicon dropdown-text"
                >
                  <Select.Option key="Email" style={{ fontSize: 16 }}>
                    Email
                  </Select.Option>
                  <Select.Option key="First name" style={{ fontSize: 16 }}>
                    First name
                  </Select.Option>
                  <Select.Option key="Last name" style={{ fontSize: 16 }}>
                    Last name
                  </Select.Option>
                  <Select.Option key="Company" style={{ fontSize: 16 }}>
                    Company
                  </Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="search_value"
                label={<span style={{ fontSize: 18,fontWeight: 600 }}>Search Users</span>}
                style={{ marginRight: "10px" }}
              >
                <Input
                  style={{ width: "270px", height: "44px", fontSize: 18 }}
                  placeholder={`Enter ${searchOption}`}
                />
              </Form.Item>
              <Form.Item className="companysearchbtn">
                <Button
                  className="save-btn font-family-style"
                  htmlType="submit"
                  type="primary"
                  style={{
                    height: "auto",
                    width: "auto",
                    background: "#416B88",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 18,
                    padding: "5px 15px",
                  }}
                  disabled={tableloading}
                >
                  Search
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <div>
          {tableloading ? (
            <div style={{ background: "#fff", padding: 20 }}>
              <Skeleton active paragraph={{ rows: 7 }} />{" "}
            </div>
          ) : (
            <Table
              className="tablecell"
              bordered
              size="middle"
              dataSource={Usersdata}
              columns={columns}
            ></Table>
          )}
        </div>
      </div>
      <Modal
        visible={isInviteModalOpen}
        closable={true}
        centered
        width={430}
        footer={[]}
        style={{ borderRadius: "20px" }}
        className="suspend-company-footer"
        onCancel={handleCancel}
      >
        <div className="delete-modal-body">
          <img
            src={InviteModalIcon}
            alt="suspend company"
            style={{ margin: "0 auto", display: "flex" }}
          />
          <Typography.Title
            style={{
              fontSize: "20px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "center",
              paddingTop: "13px",
            }}
          >
            Invite Team Members
          </Typography.Title>
          <Typography.Text
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 400,
            }}
          >
            Share direct invitation to your team members so they <br />
            can join XaaS Pricing with your organization account ID
          </Typography.Text>
          <Form
            layout="vertical"
            className="input-modal"
            //form={inviteform}
            requiredMark={false}
            style={{ paddingTop: "13px" }}
          >
            <Form.Item
              label="Enter Email ID"
              name="emails"
              rules={[
                {
                  required: true,
                  message: "This field can not be empty!",
                },
              ]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                tokenSeparators={[","]}
                notFoundContent=""
                onChange={validateEmail}
                placeholder={
                  <span style={{ color: "grey" }}>Enter email id</span>
                }
              >
                {/* {children} */}
              </Select>
            </Form.Item>
            <Form.List name="contacts">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <>
                      <Form.Item key={field.key} style={{ marginBottom: 0 }}>
                        <DeleteOutlined
                          style={{
                            position: "absolute",
                            zIndex: 1,
                            right: 0,
                            top: 0,
                            color: "#CACACA",
                          }}
                          onClick={() => remove(field.name)}
                        />
                        <Form.Item
                          label="Enter Email ID"
                          name="emails"
                          rules={[
                            {
                              required: true,
                              message: "This field can not be empty!",
                            },
                          ]}
                        >
                          <Select
                            mode="tags"
                            style={{ width: "100%" }}
                            tokenSeparators={[","]}
                            notFoundContent=""
                            onChange={validateEmail}
                            placeholder={
                              <span style={{ color: "grey" }}>
                                Enter email id
                              </span>
                            }
                          >
                            {/* {children} */}
                          </Select>
                        </Form.Item>
                      </Form.Item>
                    </>
                  ))}
                  <Form.Item>
                    <Button
                      onClick={() => add()}
                      type="link"
                      style={{ padding: 0, color: "#416B88", width: "60px" }}
                      icon={<PlusOutlined />}
                    >
                      Add more
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
          <Button
            style={{
              display: "flex",
              margin: "0 auto",
              width: "100%",
              height: "35px",
              background: "#416B88",
              alignItems: "center",
              justifyContent: "center",
              color: "#FFFFFF",
              fontSize: "14px",
              fontWeight: "600",
            }}
            // loading={loading}
            // onClick={onInviteSend}
          >
            Share Invite
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Users;
