import React, { Component } from "react";
import Index from "../Login/background";
import { Row, Col, Input, Form, Checkbox, Button, Alert } from "antd";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import Footer from "../../../common/footer";
import { emailPattern } from "../../../utility/constants";
import { Auth } from "aws-amplify";
import pdf from "../../../assets/pdf/XaaS Pricing Privacy Policy.pdf";

export class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      error: "",
      loading: false,
      Tnc: false,
    };
  }

  // Function to handle signup
  handleSignUp = (e) => {
    if (e.firstName.length <= 1 || e.lastName.length <= 1) {
      this.setState({
        error: "Please enter valid name",
      });
    } else {
      this.setState(
        {
          loading: true,
          error: "",
        },
        async () => {
          try {
            await Auth.signUp({
              username: e.email,
              password: e.password,
              attributes: {
                email: e.email,
                given_name: e.firstName,
                family_name: e.lastName,
              },
            });
            localStorage.setItem("given_name", e.firstName);
            window?.freshpaint?.identify(e?.email, {
              email: e?.email,
              name: `${e?.firstName} ${e?.lastName}`,
            });
            this.setState(
              {
                loading: false,
              },
              () => {
                // Go to ConfirmationAccount Screen
                this.props.history.push({
                  pathname: `/confirm_email`,
                  state: { feedback: "", userName: e.email },
                });
                localStorage.setItem("User", window.btoa(e.email));
              }
            );
          } catch (error) {
            console.log("error signing up:", error);
            this.setState({
              error: error.message,
              loading: false,
            });
          }
        }
      );
    }
  };

  render() {
    const { loading, Tnc, error } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center">
        <Index />
        <Col span={12} md={12} lg={12} xs={24}>
          <Row
            gutter={24}
            justify="center"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100vh",
              width: "100%",
              position: "relative",
              margin: 0,
            }}
          >
            <Col
              span={12}
              md={24}
              lg={12}
              xs={24}
              sm={24}
              style={{ textAlign: "left" }}
            >
              <img
                src={Xaaslogo}
                alt=""
                style={{ display: "flex", margin: "0 auto 20px", height: 100 }}
              />
              {error && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                requiredMark={false}
                layout="vertical"
                name="normal_login"
                className="login-form"
                onFinish={(e) => this.handleSignUp(e)}
              >
                <Row
                  style={{ display: "flex", justifyContent: "space-between" }}
                  gutter={10}
                >
                  <Col span={12}>
                    <Form.Item
                      label={
                        <span style={{ fontSize: 18, fontWeight: 400 }}>
                          First Name
                        </span>
                      }
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your First Name!",
                        },
                        {
                          pattern: /^[a-zA-Z]+$/,
                          message:
                            "symbol, number or whitespace are not allowed",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          borderRadius: "8px",
                          height: "44px",
                          fontSize: 18,
                        }}
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <span style={{ fontSize: 18, fontWeight: 400 }}>
                          Last Name
                        </span>
                      }
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your Last Name!",
                        },
                        {
                          pattern: /^[a-zA-Z]+$/,
                          message:
                            "symbol, number or whitespace are not allowed",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          borderRadius: "8px",
                          height: "44px",
                          fontSize: 18,
                        }}
                        disabled={loading}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label={
                    <span style={{ fontSize: 18, fontWeight: 400 }}>Email</span>
                  }
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      borderRadius: "8px",
                      height: "44px",
                      fontSize: 18,
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span style={{ fontSize: 18, fontWeight: 400 }}>
                      Create Password
                    </span>
                  }
                  name="password"
                  rules={[
                    {
                      min: 8,
                      message: "Password must be minimum 8 characters.",
                    },
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    style={{
                      borderRadius: "8px",
                      height: "44px",
                      fontSize: 18,
                    }}
                    disabled={loading}
                  />
                </Form.Item>
                <Form.Item
                  style={{ textAlign: "left" }}
                  name="Terms&Conditions"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(
                              new Error("Please agree with terms & conditions!")
                            ),
                    },
                  ]}
                >
                  <Checkbox
                    onChange={(e) => this.setState({ Tnc: e.target.checked })}
                    value={Tnc}
                  >
                    <span style={{ fontSize: 14 }}>I agree to the</span>&nbsp;
                    <a
                      style={{
                        fontSize: 14,
                        color: "#799FB9",
                        fontWeight: 600,
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.xaaspricing.com/terms-of-use/"
                    >
                      Terms and Conditions
                    </a>
                  </Checkbox>
                </Form.Item>
                <div
                  style={{ paddingBottom: 20, textAlign: "left", fontSize: 14 }}
                >
                  By registering, you agree to the processing of your personal
                  data by XaaS Pricing as described in our{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={pdf}
                    style={{ color: "#799FB9", fontWeight: 600 }}
                  >
                    Privacy Policy
                  </a>
                </div>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Button
                      type="link"
                      onClick={() => history.push("/signin")}
                      disabled={loading}
                      style={{ paddingLeft: 0 }}
                    >
                      <span
                        style={{
                          fontWeight: 600,
                          fontSize: "18px",
                          textAlign: "center",
                          color: "#416B88",
                          height: "auto",
                        }}
                      >
                        Login
                      </span>
                    </Button>
                    <Button
                      size="large"
                      htmlType="submit"
                      block
                      tabIndex={5}
                      style={{
                        background: "#416B88",
                        color: "#FFFFFF",
                        borderRadius: "8px",
                        height: "44px",
                        marginBottom: 0,
                        fontSize: 18,
                        fontWeight: "bold",
                        width: "fit-content",
                      }}
                      loading={loading}
                    >
                      <span>Sign Up</span>
                    </Button>
                    {/* </Col> */}
                  </Row>
                </Form.Item>
              </Form>
            </Col>
            <Footer />
          </Row>
        </Col>
      </Row>
    );
  }
}
export default CreateAccount;
