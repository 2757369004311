/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://la3kbt62p5fbtlpqlc2ebcugbu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "XaaSPricingREST",
            "endpoint": "https://s6wd9c1zjf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:e244b68a-8cc0-4e79-926b-51aa693a5cf2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_f02oCWOTe",
    "aws_user_pools_web_client_id": "3afr1b9abh9pdnmggmbsaq2d00",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "aasricing-20220407135447-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dpoyb3uo7u5vl.cloudfront.net",
    "aws_user_files_s3_bucket": "date120058-prod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
