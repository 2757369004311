/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import NewAccount from "./NewAccount";
import RequestAccess from "./RequestAccess";
import IndividualSignin from "./IndividualSignin";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import Footer from "../../../common/footer";
import Auth from "@aws-amplify/auth";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginData,
  getCustomerAccountRequests,
} from "../../../store/action/loginAction";
import RequestPending from "./RequestPending";

const NEW = "newAccount";
const ACCESS = "requestAccess";
const Selection = (props) => {
  const [step, setStep] = useState("");
  const [requestAccessState, setrequestAccessState] = useState(false);
  const [accountid, setAccountId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { accountRequests, groups } = useSelector(
    (state) => state.loginReducer
  );
  const handleRequestAccessState = (value) => {
    setrequestAccessState(true);
  };

  async function fetchuser() {
    if (groups?.length === 0) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();

        const currentSession = await Auth.currentSession();
        await cognitoUser.refreshSession(
          currentSession.refreshToken,
          async (err, session) => {
            if (session) {
              const { accessToken } = session;
              if ("cognito:groups" in accessToken.payload) {
                let userAccountId = [];
                userAccountId = accessToken.payload["cognito:groups"];

                userAccountId.push(
                  userAccountId.splice(userAccountId.indexOf("admin"), 1)[0]
                );
                clearInterval();
                const response = Auth.currentUserInfo();
                response
                  .then((data) => {
                    dispatch(setLoginData(session, data?.attributes));
                    clearInterval();
                  })
                  .then((_) => {
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  });
              }
            }
          }
        );
      } catch (e) {
        console.log("Unable to refresh Token", e);
      }
    }
  }

  useEffect(() => {
    if (
      window.location.href.includes("requestid=") &&
      window.location.href.includes("/setup")
    ) {
      setAccountId(window.location.href.split("requestid=")[1]);
      setStep(ACCESS);
    }
  }, []);

  useEffect(
    () => {
      const checkCondition = async () => {
        if (accountRequests.length >= 1) {
          setrequestAccessState(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      };
      checkCondition();
    },
    // eslint-disable-next-line
    [accountRequests]
  );

  useEffect(() => {
    if (requestAccessState) {
      setInterval(() => {
        fetchuser();
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAccessState]);

  useEffect(() => {
    dispatch(getCustomerAccountRequests());
    return () => {
      clearInterval();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isLoading && <div className="loading">Loading;</div>}
      <Row justify="center">
        <Row style={{ width: "100%", marginBottom: 30 }}>
          <Col span={24} style={{ textAlign: "center", marginTop: 40 }}>
            <img src={Xaaslogo} alt="" style={{ height: 100 }} />
          </Col>
        </Row>
        <Col span={24}>
          {requestAccessState && !accountid ? (
            <RequestPending accountid={accountid} />
          ) : (
            <></>
          )}

          {!requestAccessState && accountRequests.length === 0 && (
            <>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col span={10}>
                  <NewAccount
                    select={() => setStep(NEW)}
                    expand={step === NEW}
                    reset={() => setStep("")}
                    fetchuser={fetchuser}
                  />
                </Col>
                <Col span={10}>
                  <RequestAccess
                    select={() => setStep(ACCESS)}
                    expand={step === ACCESS}
                    reset={() => setStep("")}
                    requestAccess={handleRequestAccessState}
                    accountid={accountid}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12} offset={6}>
                  <IndividualSignin
                    select={() => setStep(NEW)}
                    expand={step === NEW}
                    reset={() => setStep("")}
                    fetchuser={fetchuser}
                  />
                </Col>
              </Row>
            </>
          )}
        </Col>
        <Footer />
      </Row>
    </>
  );
};

export default Selection;
