/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import * as QuickSightEmbedding from "amazon-quicksight-embedding-sdk";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import "./Analytics.css";
import { Button, Modal, Typography } from "antd";
import upgradePlan from "../../assets/upgradeplan.svg";
import { useHistory } from "react-router-dom";
import { InfoCircleOutlined } from "@ant-design/icons";
function DiscountingModel() {
  const history = useHistory();
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  // eslint-disable-next-line
  const [company, setCompany] = useState([]);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const { accountDetails, groups } = useSelector((state) => state.loginReducer);
  const dashboardRef = useRef();
  const superAuthorAccess = useSelector((state) => state.loginReducer);

  //quicksight embed
  function dashboardloadCallback() {
    setLoader(false);
  }

  function dashboardErrorCallback() {
    setLoader(false);
  }
  const embedDiscountingModel = async (id) => {
    setLoader(true);

    let apiURL = `/getEmbedUrl/getPricingAndDiscounting?account=${accountDetails?.id}`;
    await API.get("XaaSPricingREST", apiURL)
      .then((res) => {
        const options = {
          url: res?.EmbedUrl,
          container: document.getElementById("iframeDiscounting"),
          scrolling: "yes",
          width: "100%",
          locale: "en-US",
          footerPaddingEnabled: true,
          undoRedoDisabled: true,
          resetDisabled: true,
          parameters: {
            Competitor: setCompnaysData(),
            CompanySegment: accountDetails?.category,
          },

          loadCallback: () => dashboardloadCallback(),
          errorCallback: () => dashboardErrorCallback(),
        };
        dashboardRef?.current && QuickSightEmbedding.embedDashboard(options);
        setLoader(false);
      })
      .catch((err) => {
        setUpgradeModal(true);
        setLoader(false);
      });
  };
  const embed = async (id) => {
    setLoader(true);
    let apiURL = "";
    if (groups?.includes("author")) {
      apiURL = `/getEmbedUrl/getAuthorUrl`;
    }

    let res = await API.get("XaaSPricingREST", apiURL).catch((error) => {
      console.log("error", error);
      setLoader(false);
    });
    const options = {
      url: res?.EmbedUrl,
      container: document.getElementById("iframeDiscounting"),
      scrolling: "yes",
      width: "100%",
      locale: "en-US",
      footerPaddingEnabled: true,
      undoRedoDisabled: true,
      resetDisabled: true,
      loadCallback: () => dashboardloadCallback(),
      errorCallback: () => dashboardErrorCallback(),
    };
    dashboardRef?.current && QuickSightEmbedding.embedDashboard(options);
    setLoader(false);
  };

  //nedd for future
  // const getCancelSubscriptionData = async () => {
  //    await sdk.getCancelSubscription(
  //     accountDetails?.stripeSubscriptionID
  //   ).then((res)=> {
  //     if(res?.error){
  //       if (accountDetails?.planID === "") {
  //         setUpgradeModal(true);
  //       }
  //     }else{
  //       if (accountDetails?.planID === "") {
  //         setUpgradeModal(true);
  //       }
  //     else {
  //       if ((dashboardRef && accountDetails?.planID !== null) || groups.includes('admin'))  {
  //         embedDiscountingModel();
  //         embed("2cc563e0-2aa0-4a02-87c2-ea6a02433d66");
  //       }
  //     }
  //   }
  //   }).catch(err => {
  //     message.error("request account does not exist")
  //   })
  // };

  useEffect(() => {
    if (accountDetails?.planID === "") {
      setUpgradeModal(true);
    } else {
      if ((dashboardRef || groups.includes("admin"))  && accountDetails?.accountStatus !== null) {
        embedDiscountingModel();
        embed("2cc563e0-2aa0-4a02-87c2-ea6a02433d66");
      }
    }
    // getCancelSubscriptionData();
  }, [superAuthorAccess?.superAuthorAccess]);

  const setCompnaysData = () => {
    let data = [];
    if (
      accountDetails?.segment_comapany !== null &&
      accountDetails?.segment_comapany !== undefined
    ) {
      JSON.parse(accountDetails?.segment_comapany).map((item) => {
        data.push(item?.company_name);
      });
      return data;
    }
    setCompany(data);
  };

  useEffect(() => {
    setCompnaysData();
  }, []);

  return (
    <>
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              className="about-graphs"
              onClick={() =>
                history.push({
                  pathname: "/graph-definition",
                  state: { PackageData: "true" },
                })
              }
            >
              Graph Definitions
            </span>
            <span
              style={{
                fontStyle: "italic",
                color: "#416B88",
                fontSize: "14px",
                fontWeight: 600,
                marginRight: 10,
              }}
            >
              <InfoCircleOutlined />
            </span>
          </div>
        </div>
        {!superAuthorAccess?.superAuthorAccess ? (
          <div
            ref={dashboardRef}
            id="iframeDiscounting"
            className="dashboardChart iframe"
            style={{ position: "relative" }}
          >
            <span class="skeleton-square1" style={{ width: "49%" }}></span>
            <span class="skeleton-square2" style={{ width: "49%" }}></span>
            <span class="skeleton-box" style={{ width: "100%" }}></span>
          </div>
        ) : (
          <div
            className="iframeBox iframe"
            ref={dashboardRef}
            id="iframeDiscounting"
            style={{ position: "relative" }}
          >
            <span class="skeleton-square1" style={{ width: "49%" }}></span>
            <span class="skeleton-square2" style={{ width: "49%" }}></span>
            <span class="skeleton-box" style={{ width: "100%" }}></span>
          </div>
        )}
      </>

      <Modal
        className="planIntroModal"
        centered
        footer={null}
        title={null}
        visible={upgradeModal}
      >
        <div
          style={{ display: "flex", justifyContent: "center", paddingTop: 40 }}
        >
          <img src={upgradePlan} alt="" />
        </div>
        <Typography.Title
          style={{
            fontSize: 22,
            textAlign: "center",
            paddingTop: 15,
            fontWeight: 600,
          }}
        >
          Upgrade Your Plan!{" "}
        </Typography.Title>
        <Typography.Text
          style={{ fontSize: 16, display: "flex", justifyContent: "center" }}
        >
          Access data and insights on the pricing strategies
        </Typography.Text>
        <Typography.Text
          style={{ fontSize: 16, display: "flex", justifyContent: "center" }}
        >
          of your market and your competitors.
        </Typography.Text>
        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
          <Button
            onClick={() => history.push("/settings")}
            style={{
              height: "auto",
              background: "#416B88",
              width: "200px",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              fontWeight: 600,
              fontSize: 16,
              padding: "7px 20px",
            }}
          >
            View Pricing Plans
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default DiscountingModel;
