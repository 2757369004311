import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import ProductIcon from "../../assets/productIcon.svg";
import { Table, Skeleton, Col, Empty } from "antd";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
function PackagingModelProfile() {
  const { id } = useParams();
  const [productName, setProductName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [TableLoading, setTableLoading] = useState(false);

  const getPackageModel = async () => {
    setTableLoading(true);
    let path = `/redshiftDataManager/getSpecificCompanyDetails?companyid=${id}`;
    await API.get("XaaSPricingREST", path)
      .then((res) => {
        let data = Object.keys(res?.products);
        setProductName(data[0]);
        let tempKey = Object.keys(res?.products);
        let temp = [];

        Object.values(res?.products[tempKey[0]]).map((item) => {
          if (item !== null) {
            Object.entries(item).map(([key, value]) => {
              if (value.length > 0) {
                if (value?.[0]?.["product packaging model"]) {
                  value.map((val) => {
                    val["product"] = key;
                    return val;
                  });
                  value.sort((a, b) => a.product.localeCompare(b.product));
                  temp.push(value);
                }
              }
              return temp;
            });
          }
          return temp;
        });

        setTableData(temp);
        setTableLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getPackageModel();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Product Edition Name",
      dataIndex: "product",
      key: "product",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Product Packaging Model",
      dataIndex: "product packaging model",
      key: "product packaging model",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Tiering Approach",
      dataIndex: "tiering approach",
      key: "tiering approach",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Number Of Usage Factors",
      dataIndex: "number of usage factors",
      key: "number of usage factors",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Primary Value Metric",
      dataIndex: "primary value metric",
      key: "primary value metric",
      render: (record) => <span className="data-size">{record}</span>,
    },
    //for future fields
    // {
    //   title: "Secondary Value Metric",
    //   dataIndex: "secondary",
    //   key: "secondary",
    // },
    // {
    //   title: "Tertiary Value Metric",
    //   dataIndex: "tertiary",
    //   key: "tertiary",
    // },
    {
      title: "Product Minimum (In Pricing Model)",
      dataIndex: "product minimum (in pricing model units)",
      key: "product minimum (in pricing model units)",
      width: "15%",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Product Maximum (In Value Metric)",
      dataIndex: "product maximum (in value metric units)",
      key: "product maximum (in value metric units)",
      width: "15%",
      render: (record) => <span className="data-size">{record}</span>,
    },
    //for future fields
    // {
    //   title: "Secondary Product Maximum (In Value)",
    //   dataIndex: "productminimum2",
    //   key: "productminimum2",
    //   width: "3%",
    // },
    // {
    //   title: "Tiertiary Product Maximum (In Value)",
    //   dataIndex: "productminimum3",
    //   key: "productminimum3",
    //   width: "3%",
    // },
  ];

  return (
    <div className="main-product-overview-wrapper">
      {TableLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
          <Skeleton active paragraph={{ rows: 7 }} />{" "}
        </div>
      ) : tableData?.length > 0 ? (
        <>
          <div className="col-icon">
            <div>
              <img src={ProductIcon} alt="" />
            </div>
            <div className="col-text">
              <span className="text-wrapper-title">{productName}</span>
            </div>
          </div>
          <div className="table-wrapper">
            {tableData.map((dataSource, index) => {
              return (
                <Table
                  key={index}
                  className="table-css"
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                ></Table>
              );
            })}
          </div>
        </>
      ) : (
        <Col span={24}>
          <Empty className="empty-data" />
        </Col>
      )}
    </div>
  );
}

export default PackagingModelProfile;
