import React, { useEffect, useState } from "react";
import { getPlanList, setAccountDetails } from "../../store/action/loginAction";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  List,
  Typography,
  message,
  Modal,
  Row,
  Col,
  Skeleton,
} from "antd";
import "./billing.css";
import proPlusIcon from "../../assets/ProPlusIcon.svg";
import proIcon from "../../assets/ProIcon.svg";
import freeIcon from "../../assets/FreeIcon.svg";
import { CheckOutlined } from "@ant-design/icons";
import sdk from "../../sdk/Accounts";
import { loadStripe } from "@stripe/stripe-js";
import { useHistory } from "react-router";
import { API } from "aws-amplify";
import SelectedIcon from "../../assets/Selected.svg";
import ContactIcon from "../../assets/ContactIcon.svg";

function Index() {
  const { accountDetails } = useSelector((state) => state.loginReducer);
  const { groups } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [plans, setPlans] = useState([]);
  const [planLoader, setPlanLoader] = useState(false);
  const [selectedIndex, setselectedIndex] = useState(null);
  const [assignIndex, setassignIndex] = useState(null);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(true);
  const [stripe, setStripe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [acct, setAcct] = useState(null);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
  const [list] = useState([]);
  const history = useHistory();
  const [cancelPlanModal, setcancelPlanModal] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  //for selecting plan
  const selectPlan = async (plan, index) => {
    setPlanLoader(true);
    const accountID = groups.length > 0 ? groups[0] : null;

    if (plan && plan.id && accountID) {
      setPlanLoader(true);
      setselectedIndex(index);
      try {
        let sessionResult = await sdk.getOrgCheckoutSession(
          plan.stripePriceID[0],
          plan.id,
          accountID
        );
        if (sessionResult.type === "checkout" && sessionResult.sessionId) {
          setLoader(false);

          stripe
            .redirectToCheckout({
              sessionId: sessionResult.sessionId,
            })
            .catch((e) => {
              console.log(e);
              message.error("Failed to update plan information.");
              setLoading(false);
              setPlanLoader(false);
            });
        } else if (sessionResult?.type === "update") {
          message.success("Plan Updated successfully");
          setPlanLoader(false);
          setTimeout(() => {
            fetchAccountDetails();
          }, 5000);
          setLoading(false);
        } else {
          message.error("Failed to update plan information.");
          setLoading(false);
          setPlanLoader(false);
        }
      } catch (error) {
        console.log("error creating stripe session", error);
        message.error("Failed to update plan information.");
        setLoading(false);
        setPlanLoader(false);
      }
    }
    setLoading(false);
    setselectedIndex(null);
  };

  //for fetching details
  const fetchAccountDetails = async () => {
    setLoading(true);
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      dispatch(setAccountDetails(account));
      setAcct(account);
      setLoading(false);
    }
  };

  //list item for card
  const dataPlan = [
    {
      title:
        "Competitive/peer price benchmarking and strategy research studies",
    },
    {
      title:
        "Customer pricing research (willingness-to-pay studies, qualitative research, feature assessments,etc.)",
    },
    {
      title:
        "Pricing strategy reviews, workshops, projects and ongoing pricing consulting",
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "col1",
      width: "30%",
    },
    {
      title: "",
      className: "column-money",
      dataIndex: "col2",
    },
  ];

  //for managing billing
  const Managebilling = async (id) => {
    setPlanLoader(true);
    await sdk
      .getManagebilling(id)
      .then((res) => {
        window.location.href = res?.url;
        setPlanLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setPlanLoader(false);
      });
  };

  const showModal = () => {
    setcancelPlanModal(true);
  };

  //for admin to assign plan
  const AssignPlan = async (planId, index) => {
    setPlanLoader(true);
    setassignIndex(index);
    let init = {
      body: {
        account: groups[0],
        planid: planId,
      },
    };
    API.post("XaaSPricingREST", `/assignPlan`, init)
      .then((_) => {
        message.success("Plan updated successfully");
        setPlanLoader(false);
        setLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        message.error("Something went wrong");
        setPlanLoader(false);
        setLoading(false);
      });
    setLoading(false);
    setassignIndex(null);
  };

  //for fetching plans from api
  const fetchPlans = async () => {
    await dispatch(getPlanList())
      .then((res) => {
        const data = [
          {
            title: `${res[2]?.bulletPoints?.[1]}`,
          },
          {
            title: `${res[2]?.bulletPoints?.[2]}`,
          },
          {
            title: `${res[2]?.bulletPoints?.[3]}`,
          },
          {
            title: `${res[2]?.bulletPoints?.[4]}`,
          },
        ];
        const ProData = [
          {
            title: `${res[1]?.bulletPoints?.[1]}`,
          },
          {
            title: `${res[1]?.bulletPoints?.[2]}`,
          },
          {
            title: `${res[1]?.bulletPoints?.[3]}`,
          },
          {
            title: `${res[1]?.bulletPoints?.[4]}`,
          },
          {
            title: `${res[1]?.bulletPoints?.[5]}`,
          },
        ];
        const ProPlusData = [
          {
            title: `${res[0]?.bulletPoints?.[1]}`,
          },
          {
            title: `${res[0]?.bulletPoints?.[2]}`,
          },
          {
            title: `${res[0]?.bulletPoints?.[3]}`,
          },
          {
            title: `${res[0]?.bulletPoints?.[4]}`,
          },
          {
            title: `${res[0]?.bulletPoints?.[5]}`,
          },
        ];

        let pricePlan = {};
        let proPricePlan = {};
        let prices = JSON.parse(res[1]?.Pricing);
        pricePlan = Object.keys(prices).filter((key) => key.includes("price"));
        let priceSplit = prices[pricePlan]?.value?.split("/");

        let pricesData = JSON.parse(res[0]?.Pricing);
        proPricePlan = pricesData[Object.keys(pricesData)[2]];
        let proPlanSplit = proPricePlan?.value?.split("/");
        setPlans([
          {
            key: "1",
            col1: (
              <div className="div-wrapper">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img src={freeIcon} alt="" />
                  {accountDetails?.planID === res[2]?.id && (
                    <img src={SelectedIcon} alt="" />
                  )}
                </div>
                <span className="text-wrapper-data">{res[2]?.name}</span>
                <span className="detail-data">{res[2]?.Targets}</span>
                <div className="btn-class">
                  {groups.includes("admin") && (
                    <Button
                      loading={assignIndex === 0}
                      className="btn-class-css"
                      onClick={() => AssignPlan(res[2]?.id, 0)}
                    >
                      Assign Plan
                    </Button>
                  )}
                </div>
              </div>
            ),
            col2: (
              <div className="col-2-class">
                <span className="heading-text">
                  {res[2]?.bulletPoints?.[0]}
                </span>

                <List
                className="bulletpoint"
                  itemLayout="horizontal"
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item className="box-card">
                      <List.Item.Meta
                        avatar={
                          <CheckOutlined
                            className="tick checkoutlined"
                            style={{ strokeWidth: 50, stroke: "#416B88" }}
                          />
                        }
                        title={
                          <Typography.Text className="typogrphy-text">
                            {item.title}
                          </Typography.Text>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            ),
          },
          {
            key: "2",
            col1: (
              <div className="div-wrapper">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <img src={proIcon} alt="" />
                  {accountDetails?.planID === res[1]?.id && (
                    <img src={SelectedIcon} alt="" />
                  )}
                </div>
                <span className="text-wrapper-data">{res[1]?.name}</span>
                <span className="start-text">
                  {JSON.parse(res[1]?.Pricing)?.Title?.[0]}
                </span>
                <span className="rs-css">
                  {priceSplit[0]}
                  <span className="rsd-css">
                    /{priceSplit[1]}/{priceSplit[2]}
                  </span>
                </span>
                <span className="discount-text">
                  {JSON.parse(res[1]?.Pricing)?.Discount?.[0]}
                </span>
                <div className="trial-text">
                  <span>{res[1]?.Targets}</span>
                </div>

                <div className="btn-class">
                  {accountDetails?.accountStatus === "FREE_TRIAL_END" &&
                  acct?.planID === res[2]?.id ? (
                    <Button
                      loading={selectedIndex === 1}
                      className="select-plan-btn"
                      block
                      size="large"
                      style={{
                        borderColor: "#416B88",
                        width: "auto",
                        backgroundColor:
                          acct?.planID === res[2]?.id ? "#416B88" : "",
                        color: acct?.planID === res[2]?.id ? "white" : "",
                      }}
                      onClick={() => Managebilling(accountDetails?.id)}
                    >
                      <strong style={{ fontSize: 18, fontWeight: 600 }}>
                        Manage Billing
                      </strong>
                    </Button>
                  ) : (
                    <div style={{ display: "flex", gap: 10 }}>
                      <Button
                        disabled={
                          acct?.planID === res[1]?.id &&
                          acct?.accountStatus !== "FREE_TRIAL_END"
                        }
                        loading={selectedIndex === 1}
                        className={
                          accountDetails?.accountStatus === "PENDING_PAYMENT"
                            ? "choose-btn"
                            : "btn-class-css"
                        }
                        onClick={() => selectPlan(res[1], 1)}
                      >
                        {accountDetails?.accountStatus === null ? (
                          <span>Start 14 Day Trial</span>
                        ) : accountDetails?.accountStatus ===
                            "PENDING_PAYMENT" ||
                          accountDetails?.plan === null ||
                          accountDetails?.planID === null ? (
                          <span>Choose Plan</span>
                        ) : accountDetails?.planID === res[1]?.id ? (
                          <span>Selected Plan</span>
                        ) : (
                          <span>Update Plan</span>
                        )}{" "}
                      </Button>
                      {accountDetails?.planID === res[1]?.id &&
                        accountDetails?.stripeSubscriptionID !== "" && (
                          <Button
                            className="btn-class-css"
                            block
                            size="large"
                            onClick={showModal}
                            style={{
                              borderColor: "#959595",
                              width: "auto",
                              backgroundColor: "white",
                              color: "#959595",
                              fontSize: 18,
                              fontWeight: 600,
                            }}
                          >
                            Cancel Plan
                          </Button>
                        )}
                    </div>
                  )}
                  {groups.includes("admin") && (
                    <Button
                      loading={assignIndex === 1}
                      className="btn-class-css"
                      onClick={() => AssignPlan(res[1]?.id, 1)}
                    >
                      Assign Plan
                    </Button>
                  )}
                </div>
              </div>
            ),
            col2: (
              <div className="col-2-class">
                <span className="heading-text">
                  {res[1]?.bulletPoints?.[0]}
                </span>

                <List
                 className="bulletpoint"
                  itemLayout="horizontal"
                  dataSource={ProData}
                  renderItem={(item) => (
                    <List.Item className="box-card">
                      <List.Item.Meta
                        avatar={
                          <CheckOutlined
                            className="tick checkoutlined"
                            style={{ strokeWidth: 50, stroke: "#416B88" }}
                          />
                        }
                        title={
                          <Typography.Text className="typogrphy-text">
                            {item.title}
                          </Typography.Text>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            ),
          },
          {
            key: "3",
            col1: (
              <div className="div-wrapper">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <img src={proPlusIcon} alt="" />
                  {accountDetails?.planID === res[0]?.id && (
                    <img src={SelectedIcon} alt="" />
                  )}
                </div>
                <span className="text-wrapper-data">{res[0]?.name}</span>
                <span className="start-text">
                  {JSON.parse(res[0]?.Pricing)?.Title?.[0]}
                </span>
                <span className="rs-css">
                  {proPlanSplit[0]}
                  <span className="rsd-css">/{proPlanSplit[1]}</span>
                </span>
                <span className="trial1-text">{res[0]?.Targets}</span>
                <div style={{ gap: 10, display: "flex" }}>
                  <Button
                    className="btn-touch-class-css"
                    onClick={() => history.push("/support")}
                  >
                    Get In Touch
                  </Button>
                  {groups.includes("admin") && (
                    <Button
                      loading={assignIndex === 1}
                      className="btn-class-css"
                      onClick={() => AssignPlan(res[0]?.id, 1)}
                    >
                      Assign Plan
                    </Button>
                  )}
                </div>
              </div>
            ),
            col2: (
              <div className="col-2-class">
                <span className="heading-text">
                  {res[0]?.bulletPoints?.[0]}
                </span>

                <List
                 className="bulletpoint"
                  itemLayout="horizontal"
                  dataSource={ProPlusData}
                  renderItem={(item) => (
                    <List.Item className="box-card">
                      <List.Item.Meta
                        avatar={
                          <CheckOutlined
                            className="tick checkoutlined"
                            style={{ strokeWidth: 50, stroke: "#416B88" }}
                          />
                        }
                        title={
                          <Typography.Text className="typogrphy-text">
                            {item.title}
                          </Typography.Text>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            ),
          },
        ]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //for canceling plan
  const cancelSubscriptions = async () => {
    setButtonLoader(true);
    const path = `/billing/cancelSubscription`;
    await API.post("XaaSPricingREST", path, {
      body: {
        stripeSubscriptionId: accountDetails?.stripeSubscriptionID,
      },
    })
      .then(async (res) => {
        await fetchAccountDetails()
          .then(() => {
            setButtonLoader(false);
            setLoading(false);
            setcancelPlanModal(false);
            localStorage.setItem("status", res?.subscription?.status);
            message.success("Successfully cancel your subscription");
            window.location.reload();
          })
          .catch((err) => {
            message.error("The requested account does not exist.");
          });
      })
      .catch((err) => {
        setButtonLoader(false);
        setcancelPlanModal(false);
        message.error("The requested account does not exist.");
      });
  };
  useEffect(() => {
    fetchAccountDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetchPlans();
    // eslint-disable-next-line
  }, [acct]);

  useEffect(() => {
    const resolveStripe = async () => {
      let s = await stripePromise;
      setStripe(s);
    };
    resolveStripe();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("stripe_session")) {
      history.push("/thankyou");
    }

    for (var key of params.keys()) {
      params.delete(key);
    }
    history.replace({
      search: params.toString(),
    });
    if (params.has("error")) {
      history.replace({
        search: "",
      });
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  useEffect(() => {
    let accountDetail = acct;
    if (accountDetail && list.length > 0) {
      list.find(
        (item) => item?.stripePriceID[0] === accountDetail?.stripePriceID
      );
    }
  }, [acct, list]);

  useEffect(() => {
    let account = acct;
    if (account) {
      if (!account?.url?.includes("http")) {
        account.url = "https://" + account?.url;
      }
    }
  }, [acct]);

  return (
    <>
      {planLoader && <div className="loading">Loading;</div>}
      <div className="sub-container">
        <div className="header-text">
          <span>Choose Plan</span>
        </div>
        <div className="billing-wrapper">
          <Row>
            {loading && <div className="dashboard-loader"></div> ? (
              <>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
                <Col span={6}>
                  <Skeleton
                    active
                    style={{ width: "35vh" }}
                    paragraph={{ rows: 5 }}
                  />
                </Col>
              </>
            ) : (
              <Table
                className="th-class"
                columns={columns}
                dataSource={plans}
                bordered
                pagination={false}
              />
            )}
          </Row>
        </div>
        <div className="contact-card">
          <Row justify="center">
            <Col span={20}>
              <div className="text-card">
                <span className="spanData-text">
                  Need additional help? XaaS Pricing can support you with the
                  following
                </span>
                <List
                  style={{ marginTop: 10 }}
                  itemLayout="horizontal"
                  dataSource={dataPlan}
                  renderItem={(item) => (
                    <List.Item className="box-card">
                      <List.Item.Meta
                        avatar={
                          <CheckOutlined
                            className="tick checkoutlined"
                            style={{ strokeWidth: 50, stroke: "#416B88" }}
                          />
                        }
                        title={
                          <Typography.Text className="typogrphy-text">
                            {item.title}
                          </Typography.Text>
                        }
                      />
                    </List.Item>
                  )}
                />
              </div>
            </Col>
            <Col span={4}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span
                  style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img src={ContactIcon} alt="" />
                </span>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button className="btn-touch-class-css">
                    <a href="https://www.xaaspricing.com/contact-us/">
                      Contact Us{" "}
                    </a>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div></div>
      </div>
      <Modal
        className="header-style"
        style={{ fontWeight: 600, fontSize: 18 }}
        visible={cancelPlanModal}
        footer={null}
        onCancel={() => setcancelPlanModal(false)}
      >
        <Row justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <Typography.Text style={{ fontSize: 16 }}>
              Are you sure you want to cancel your subscription?
            </Typography.Text>
          </Col>

          <Col>
            <Button
              type="secondary"
              onClick={() => setcancelPlanModal(false)}
              style={{
                marginTop: 16,
                borderColor: "black",
                padding: "4px 14px",
                fontSize: 16,
                fontWeight: 600,
                height: "auto",
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={cancelSubscriptions}
              style={{
                marginTop: 16,
                background: "#416B88",
                padding: "4px 25px",
                fontSize: 16,
                fontWeight: 600,
                height: "auto",
                marginLeft: 10,
              }}
              loading={buttonLoader}
            >
              Yes
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default Index;
