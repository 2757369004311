import React, { useRef, useState, useEffect } from "react";
import "./Graphs.css";
import {
  EditFilled,
  PlusOutlined,
  CheckOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import {
  Table,
  Button,
  message,
  Popconfirm,
  Tooltip,
  Tabs,
  Skeleton,
} from "antd";
import { useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { listGraphDefinitions } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {
  createGraphDefinition,
  updateGraphDefinition,
  deleteGraphDefinition,
} from "../../graphql/mutations";
import Moment from "react-moment";
import { useLocation } from "react-router-dom";

function GraphDefinition() {
  const location = useLocation();
  const { TabPane } = Tabs;
  const [allDataSource, setAllDataSource] = useState([]);
  const [allDataSource1, setAllDataSource1] = useState([]);
  const [allDataSource2, setAllDataSource2] = useState([]);
  const [allDataSource3, setAllDataSource3] = useState([]);
  const [allDataSource4, setAllDataSource4] = useState([]);
  const [allDataSource5, setAllDataSource5] = useState([]);
  const [allDataSource6, setAllDataSource6] = useState([]);
  const [allDataSource7, setAllDataSource7] = useState([]);
  const [allDataSource8, setAllDataSource8] = useState([]);
  const [allDataSource9, setAllDataSource9] = useState([]);
  const [loading, setLoading] = useState(false);

  //for input focus
  const inputDashboardMetricsRef = useRef([]);
  const inputDashboardDefinitionRef = useRef([]);
  const inputDashboardPricingMetricsRef = useRef([]);
  const inputDashboardPricingDefinitionRef = useRef([]);
  const inputDashboardDiscountingMetricsRef = useRef([]);
  const inputDashboardDiscountingDefinitionRef = useRef([]);
  const inputPackagMarketMetricsRef = useRef([]);
  const inputPackagMarketDefinitionRef = useRef([]);
  const inputPricingMarketMetricsRef = useRef([]);
  const inputPricingMarketDefinitionRef = useRef([]);
  const inputDiscountingMarketMetricsRef = useRef([]);
  const inputDiscountingMarketDefinitionRef = useRef([]);

  //for loader
  const [mainIndex, setMainIndex] = useState({
    packageMatricsIndex: null,
    packageDefinationIndex: null,

    productMatricsIndex: null,
    productDefinationIndex: null,

    discountingMatricsIndex: null,
    discountingDefinationIndex: null,

    packageMatricsMarketIndex: null,
    packageDefinationMarketIndex: null,

    packageMatricsCompanyIndex: null,
    packageDefinationCompanyIndex: null,

    pricingMatricsMarketIndex: null,
    pricingDefinationMarketIndex: null,

    pricingMatricsCompanyIndex: null,
    pricingDefinationCompanytIndex: null,

    discountingMatricsMarketIndex: null,
    discountingDefinationMarketIndex: null,

    discountingMatricsCompanyIndex: null,
    discountingDefinationCompanyIndex: null,
  });

  //for future use
  // const [tableShow, setTableShow] = useState(true);
  // const [tableShow1, setTableShow1] = useState(true);
  // const [tableShow2, setTableShow2] = useState(true);
  // const [tableShow3, setTableShow3] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  //for future use
  // const { Search } = Input;
  const { accountDetails, groups } = useSelector((state) => state.loginReducer);

  //for read more and show less
  const toggleReadMore = (index, col) => {
    if (col === "PackageDefination") {
      let temp = [...allDataSource1];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource1(temp);
    }
    if (col === "PricingDefination") {
      let temp = [...allDataSource2];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource2(temp);
    }
    if (col === "DiscountingDefination") {
      let temp = [...allDataSource3];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource3(temp);
    }
    if (col === "PackageMarketDefination") {
      let temp = [...allDataSource4];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource4(temp);
    }
    if (col === "PackageCompanyDefination") {
      let temp = [...allDataSource5];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource5(temp);
    }
    if (col === "PricingMarketDefination") {
      let temp = [...allDataSource6];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource6(temp);
    }
    if (col === "PricingCompanyDefination") {
      let temp = [...allDataSource7];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource7(temp);
    }
    if (col === "DiscountingMarketDefination") {
      let temp = [...allDataSource8];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource8(temp);
    }
    if (col === "DiscountingCompanyDefination") {
      let temp = [...allDataSource9];
      temp[index]["isShowMore"] = !temp[index]["isShowMore"];
      setAllDataSource9(temp);
    }
  };

  //all handle change function for text value
  const handleChange = (value, index, col) => {
    let temp = [...allDataSource];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource(temp);
  };

  const handlePackageChange = (value, index, col) => {
    let temp = [...allDataSource1];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource1(temp);
  };

  const handlePricingChange = (value, index, col) => {
    let temp = [...allDataSource2];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource2(temp);
  };

  const handleDiscountingChange = (value, index, col) => {
    let temp = [...allDataSource3];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource3(temp);
  };

  const handlePackagMarketViewChange = (value, index, col) => {
    let temp = [...allDataSource4];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource4(temp);
  };

  const handlePackagCompetitorChange = (value, index, col) => {
    let temp = [...allDataSource5];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource5(temp);
  };

  const handlePricingMarketChange = (value, index, col) => {
    let temp = [...allDataSource6];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource6(temp);
  };

  const handleDiscountingMarketChange = (value, index, col) => {
    let temp = [...allDataSource8];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource8(temp);
  };

  const handleDiscountingCompetitorChange = (value, index, col) => {
    let temp = [...allDataSource9];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
          item["isShowMore"] = true;
        }
      }
      return temp;
    });
    setAllDataSource9(temp);
  };

  const handlePricingCompetitorChange = (value, index, col) => {
    let temp = [...allDataSource7];
    temp.map((item) => {
      if (item?.id === index?.id) {
        if (col === 1) {
          item["graphql_metric"] = value;
          item["isEdit1"] = true;
        }
        if (col === 2) {
          item["graphql_description"] = value;
          item["isEdit2"] = true;
        }
      }
      return temp;
    });
    setAllDataSource7(temp);
  };

  //for future use
  // const handleAdd = () => {
  //   const newData = {
  //     id: Math.random(),
  //     graph_type: "DASHBOARD",
  //     graphql_metric: "",
  //     graphql_description: "",
  //   };
  //   setAllDataSource([...allDataSource, newData]);
  // };

  //all handleAdd function for adding new row in table
  const handleAddPackage = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PACKAGING_MODELS",
      graphql_metric: "",
      graphql_description: "",
    };
    setAllDataSource1([...allDataSource1, newData]);
  };

  const handleAddPricing = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PRICING_MODELS",
      graphql_metric: "",
      graphql_description: "",
    };
    setAllDataSource2([...allDataSource2, newData]);
  };

  const handleAddDiscounting = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PRICING_DISCOUNTING",
      graphql_metric: "",
      graphql_description: "",
    };
    setAllDataSource3([...allDataSource3, newData]);
  };

  const handleAddPackageMarket = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PACKAGING_MODELS",
      graphql_metric: "",
      graphql_description: "",
      analytics_type: "MARKET_VIEW",
    };
    setAllDataSource4([...allDataSource4, newData]);
  };

  const handleAddPackageCompetitor = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PACKAGING_MODELS",
      graphql_metric: "",
      graphql_description: "",
      analytics_type: "COMPETITOR_VIEW",
    };
    setAllDataSource5([...allDataSource5, newData]);
  };

  const handleAddPricingMarket = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PRICING_MODELS",
      graphql_metric: "",
      graphql_description: "",
      analytics_type: "PRICING_MARKET_VIEW",
    };
    setAllDataSource6([...allDataSource6, newData]);
  };

  const handleAddPricingCompetitor = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PRICING_MODELS",
      graphql_metric: "",
      graphql_description: "",
      analytics_type: "PRICING_COMPETITOR_VIEW",
    };
    setAllDataSource7([...allDataSource7, newData]);
  };

  const handleAddDiscountingMarket = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PRICING_DISCOUNTING",
      graphql_metric: "",
      graphql_description: "",
      analytics_type: "DISCOUTING_MARKET_VIEW",
    };
    setAllDataSource8([...allDataSource8, newData]);
  };
  const handleAddDiscountingCompetitor = () => {
    const newData = {
      id: Math.random(),
      graph_type: "PRICING_DISCOUNTING",
      graphql_metric: "",
      graphql_description: "",
      analytics_type: "DISCOUTING_COMPETITOR_VIEW",
    };
    setAllDataSource9([...allDataSource9, newData]);
  };

  //for fetching added data in table
  const getListDefinition = async () => {
    setTableLoader(true);
    await API.graphql(graphqlOperation(listGraphDefinitions))
      .then((res) => {
        let temp = [];
        let temp_package = [];
        let temp_pricing = [];
        let temp_discounting = [];
        let temp_package_market = [];
        let temp_package_competitor = [];
        let temp_pricing_market = [];
        let temp_pricing_competitor = [];
        let temp_discounting_market = [];
        let temo_discounting_competitor = [];
        let date = res?.data?.listGraphDefinitions?.items.sort(function (a, b) {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
        date.map((item) => {
          if (item?.graph_type === "DASHBOARD") {
            let data = { ...item, isEdit1: false, isEdit2: false };
            temp.push(data);
          }
          return temp;
        });
        let PackageDate = res?.data?.listGraphDefinitions?.items.sort(function (
          a,
          b
        ) {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
        PackageDate.map((item) => {
          if (
            item?.graph_type === "PACKAGING_MODELS" &&
            item?.analytics_type === null
          ) {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_package.push(data);
          }
          return temp;
        });
        let PricingDate = res?.data?.listGraphDefinitions?.items.sort(function (
          a,
          b
        ) {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        });
        PricingDate.map((item) => {
          if (
            item?.graph_type === "PRICING_MODELS" &&
            item?.analytics_type === null
          ) {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_pricing.push(data);
          }
          return temp;
        });
        let DiscountingDate = res?.data?.listGraphDefinitions?.items.sort(
          function (a, b) {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          }
        );
        DiscountingDate.map((item) => {
          if (
            item?.graph_type === "PRICING_DISCOUNTING" &&
            item?.analytics_type === null
          ) {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_discounting.push(data);
          }
          return temp;
        });
        let packageMarket = res?.data?.listGraphDefinitions?.items.sort(
          function (a, b) {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          }
        );
        packageMarket.map((item) => {
          if (item?.analytics_type === "MARKET_VIEW") {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_package_market.push(data);
          }
          return temp;
        });
        let packageCompany = res?.data?.listGraphDefinitions?.items.sort(
          function (a, b) {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          }
        );
        packageCompany.map((item) => {
          if (item?.analytics_type === "COMPETITOR_VIEW") {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_package_competitor.push(data);
          }
          return temp;
        });
        let pricingMarket = res?.data?.listGraphDefinitions?.items.sort(
          function (a, b) {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          }
        );
        pricingMarket.map((item) => {
          if (item?.analytics_type === "PRICING_MARKET_VIEW") {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_pricing_market.push(data);
          }
          return temp;
        });
        let pricingCompany = res?.data?.listGraphDefinitions?.items.sort(
          function (a, b) {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          }
        );
        pricingCompany.map((item) => {
          if (item?.analytics_type === "PRICING_COMPETITOR_VIEW") {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_pricing_competitor.push(data);
          }
          return temp;
        });
        let DiscountingMarket = res?.data?.listGraphDefinitions?.items.sort(
          function (a, b) {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          }
        );
        DiscountingMarket.map((item) => {
          if (item?.analytics_type === "DISCOUTING_MARKET_VIEW") {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temp_discounting_market.push(data);
          }
          return temp;
        });
        let DiscountingCompany = res?.data?.listGraphDefinitions?.items.sort(
          function (a, b) {
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          }
        );
        DiscountingCompany.map((item) => {
          if (item?.analytics_type === "DISCOUTING_COMPETITOR_VIEW") {
            let data = {
              ...item,
              isEdit1: false,
              isEdit2: false,
              isShowMore: false,
            };
            temo_discounting_competitor.push(data);
          }
          return temp;
        });

        // setDataSource(temp);
        setAllDataSource(temp);

        setDataSource1(temp_package);
        setAllDataSource1(temp_package);

        setDataSource2(temp_pricing);
        setAllDataSource2(temp_pricing);

        setDataSource3(temp_discounting);
        setAllDataSource3(temp_discounting);

        setDataSource4(temp_package_market);
        setAllDataSource4(temp_package_market);

        setDataSource5(temp_package_competitor);
        setAllDataSource5(temp_package_competitor);

        setDataSource6(temp_pricing_market);
        setAllDataSource6(temp_pricing_market);

        setDataSource7(temp_pricing_competitor);
        setAllDataSource7(temp_pricing_competitor);

        setDataSource8(temp_discounting_market);
        setAllDataSource8(temp_discounting_market);

        setDataSource9(temo_discounting_competitor);
        setAllDataSource9(temo_discounting_competitor);
        setTableLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setTableLoader(false);
      });
  };

  //All create function for creating new graph definition
  const createGraph = async (item) => {
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success("Dashboard Graph Definitions Created Successfully");
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createPackageModelGraph = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, packageMatricsIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, packageDefinationIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.isShowMore;
    delete item?.id;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Packaging Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      packageMatricsIndex: null,
      packageDefinationIndex: null,
    });
  };

  const createPricingModelGraph = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, productMatricsIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, productDefinationIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success("Pricing Model Graph Definitions Created Successfully");
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      productMatricsIndex: null,
      productDefinationIndex: null,
    });
  };

  const createDiscountingModelGraph = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, discountingMatricsIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, discountingDefinationIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Pricing & Discounting Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      discountingMatricsIndex: null,
      discountingDefinationIndex: null,
    });
  };

  const createPackageMarketView = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, packageMatricsMarketIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, packageDefinationMarketIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Packaging Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      packageMatricsMarketIndex: null,
      packageDefinationMarketIndex: null,
    });
  };

  const createPackagecompetitorView = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, packageMatricsCompanyIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, packageDefinationCompanyIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Packaging Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      packageMatricsCompanyIndex: null,
      packageDefinationCompanyIndex: null,
    });
  };
  const createPricingMarketView = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, pricingMatricsMarketIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, pricingDefinationMarketIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Pricing Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      pricingMatricsMarketIndex: null,
      pricingDefinationMarketIndex: null,
    });
  };
  const createPricingcompetitorView = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, pricingMatricsCompanyIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, pricingDefinationCompanytIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Pricing Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      pricingMatricsCompanyIndex: null,
      pricingDefinationCompanytIndex: null,
    });
  };
  const createDiscountingMarketView = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, discountingMatricsMarketIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, discountingDefinationMarketIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Pricing & Discounting Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      discountingMatricsMarketIndex: null,
      discountingDefinationMarketIndex: null,
    });
  };
  const createDiscountingCompetitor = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, discountingMatricsCompanyIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, discountingDefinationCompanyIndex: index });
    }
    delete item?.isEdit1;
    delete item?.isEdit2;
    delete item?.id;
    delete item?.isShowMore;
    await API.graphql(
      graphqlOperation(createGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Pricing & Discounting  Model Graph Definitions Created Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      discountingMatricsIndex: null,
      discountingDefinationIndex: null,
    });
  };

  //All update function for updating graph definition
  const updateGraph = async (item) => {
    delete item?.["createdAt"];
    delete item?.["updatedAt"];
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...item,
        },
      })
    )
      .then((res) => {
        message.success("Dashboard Graph Definitions Updated Successfully");
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePackageGraph = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, packageMatricsIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, packageDefinationIndex: index });
    }
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Packaging Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      packageMatricsIndex: null,
      packageDefinationIndex: null,
    });
  };

  const updatePricingGraph = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, productMatricsIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, productDefinationIndex: index });
    }

    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success("Pricing Model Graph Definitions Updated Successfully");
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      productMatricsIndex: null,
      productDefinationIndex: null,
    });
  };

  const updateDiscountingGraph = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, discountingMatricsIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, discountingDefinationIndex: index });
    }
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Pricing & Discounting Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      discountingMatricsIndex: null,
      discountingDefinationIndex: null,
    });
  };

  const updatePackageMarket = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, packageMatricsMarketIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, packageDefinationMarketIndex: index });
    }
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Packaging Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      packageMatricsMarketIndex: null,
      packageDefinationMarketIndex: null,
    });
  };

  const updatePackageCompetitor = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, packageMatricsCompanyIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, packageDefinationCompanyIndex: index });
    }
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Packaging Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      packageMatricsCompanyIndex: null,
      packageDefinationCompanyIndex: null,
    });
  };

  const updatePricingMarket = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, pricingMatricsMarketIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, pricingDefinationMarketIndex: index });
    }

    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Pricing Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setMainIndex({
      ...mainIndex,
      pricingMatricsMarketIndex: null,
      pricingDefinationMarketIndex: null,
    });
  };
  const updatePricingCompetitor = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, pricingMatricsCompanyIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, pricingDefinationCompanytIndex: index });
    }
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Competitior View of Pricing Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setMainIndex({
      ...mainIndex,
      pricingMatricsCompanyIndex: null,
      pricingDefinationCompanytIndex: null,
    });
  };
  const updateDiscountingMarket = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, discountingMatricsMarketIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, discountingDefinationMarketIndex: index });
    }
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Pricing & Discounting Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setMainIndex({
      ...mainIndex,
      discountingMatricsMarketIndex: null,
      discountingDefinationMarketIndex: null,
    });
  };
  const updateDiscountingCompetitor = async (item, index, col) => {
    setLoading(true);
    if (col === "metrics") {
      setMainIndex({ ...mainIndex, discountingMatricsCompanyIndex: index });
    }
    if (col === "defination") {
      setMainIndex({ ...mainIndex, discountingDefinationCompanyIndex: index });
    }
    delete item?.["isEdit1"];
    delete item?.["isEdit2"];
    delete item?.["isShowMore"];
    let data = {
      id: item?.id,
      graphql_description: item?.graphql_description,
      graphql_metric: item?.graphql_metric,
    };
    await API.graphql(
      graphqlOperation(updateGraphDefinition, {
        input: {
          ...data,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Pricing & Discounting Model Graph Definitions Updated Successfully"
        );
        getListDefinition();
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    setMainIndex({
      ...mainIndex,
      discountingMatricsIndex: null,
      discountingDefinationIndex: null,
    });
  };

  //All delete function for deleting graph definition
  const DeleteGraphDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success("Dashboard Graph Definitions Deleted Successfully");
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphPackageDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Packaging Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphPricingDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success("Pricing Model Graph Definitions Deleted Successfully");
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphDiscountingDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Pricing & Discounting Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphPackageMarketDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Packaging Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphPackagecompetitorDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Packaging Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphPricingMarketDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Pricing Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphPricingcompetitorDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Pricing Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphDiscountingMarketDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Market View of Pricing & Discounting Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteGraphDiscountingcompetitorDefinition = async (item) => {
    await API.graphql(
      graphqlOperation(deleteGraphDefinition, {
        input: {
          id: item?.id,
        },
      })
    )
      .then((res) => {
        message.success(
          "Company View of Pricing & Discounting Model Graph Definitions Deleted Successfully"
        );
        getListDefinition();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //For Future Use

  // const handleSearch = (sreachValue) => {
  //   if (sreachValue !== "") {
  //     let tempSearchData = dataSource.filter((item) =>
  //       item.graphql_description
  //         .toLowerCase()
  //         .includes(sreachValue.toLowerCase())
  //     );
  //     let tempSearchData1 = dataSource1.filter((item) =>
  //       item.graphql_description
  //         .toLowerCase()
  //         .includes(sreachValue.toLowerCase())
  //     );
  //     let tempSearchData2 = dataSource2.filter((item) =>
  //       item.graphql_description
  //         .toLowerCase()
  //         .includes(sreachValue.toLowerCase())
  //     );
  //     let tempSearchData3 = dataSource3.filter((item) =>
  //       item.graphql_description
  //         .toLowerCase()
  //         .includes(sreachValue.toLowerCase())
  //     );
  //     if (tempSearchData.length === 0) {
  //       setTableShow(false);
  //     } else {
  //       setTableShow(true);
  //     }
  //     if (tempSearchData1.length === 0) {
  //       setTableShow1(false);
  //     } else {
  //       setTableShow1(true);
  //     }
  //     if (tempSearchData2.length === 0) {
  //       setTableShow2(false);
  //     } else {
  //       setTableShow2(true);
  //     }
  //     if (tempSearchData3.length === 0) {
  //       setTableShow3(false);
  //     } else {
  //       setTableShow3(true);
  //     }

  //     setAllDataSource(tempSearchData);
  //     setAllDataSource1(tempSearchData1);
  //     setAllDataSource2(tempSearchData2);
  //     setAllDataSource3(tempSearchData3);
  //   } else {
  //     setTableShow(true);
  //     setTableShow1(true);
  //     setTableShow2(true);
  //     setTableShow3(true);
  //     setAllDataSource(dataSource);
  //     setAllDataSource1(dataSource1);
  //     setAllDataSource2(dataSource2);
  //     setAllDataSource3(dataSource3);
  //   }
  // };

  useEffect(() => {
    // createGraph()
    getListDefinition();
  }, []);

  //Dashboard Column
  // const [dataSource, setDataSource] = useState([]);
  //eslint-disable-next-line
  const columns = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      hidden: false,
      width: "30%",

      render: (value, item) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            onChange={(e) => handleChange(e.target.value, item, 1)}
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateGraph(item)
                      : createGraph(item);
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metrics">
                  <Button
                    className="editIcon"
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      hidden: true,
      width: "40%",

      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              onChange={(e) => handleChange(e.target.value, item, 2)}
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "PackageDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateGraph(item)
                      : createGraph(item);
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            hidden: true,
            width: "25%",

            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),

    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            hidden: true,
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //Packaging model
  const [dataSource1, setDataSource1] = useState([]);
  const columns1 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            ref={(el) => (inputDashboardMetricsRef.current[index] = el)}
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            onChange={(e) => handlePackageChange(e.target.value, item, 1)}
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePackageGraph(item, index, "metrics")
                      : createPackageModelGraph(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.packageMatricsIndex === index
                    }
                    onClick={() => {
                      inputDashboardMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              ref={(el) => (inputDashboardDefinitionRef.current[index] = el)}
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              onChange={(e) => handlePackageChange(e.target.value, item, 2)}
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "PackageDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePackageGraph(item, index, "defination")
                      : createPackageModelGraph(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.packageDefinationIndex === index
                    }
                    onClick={() => {
                      inputDashboardDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),

    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphPackageDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //Pricing model
  const [dataSource2, setDataSource2] = useState([]);
  const columns2 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) => (inputDashboardPricingMetricsRef.current[index] = el)}
            onChange={(e) => handlePricingChange(e.target.value, item, 1)}
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePricingGraph(item, index, "metrics")
                      : createPricingModelGraph(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.productMatricsIndex === index
                    }
                    onClick={() => {
                      inputDashboardPricingMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      height: "40px",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) =>
                (inputDashboardPricingDefinitionRef.current[index] = el)
              }
              onChange={(e) => handlePricingChange(e.target.value, item, 2)}
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "PricingDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePricingGraph(item, index, "defination")
                      : createPricingModelGraph(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.productDefinationIndex === index
                    }
                    onClick={() => {
                      inputDashboardPricingDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphPricingDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //pricing and discounting
  const [dataSource3, setDataSource3] = useState([]);
  const columns3 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) =>
              (inputDashboardDiscountingMetricsRef.current[index] = el)
            }
            onChange={(e) => handleDiscountingChange(e.target.value, item, 1)}
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateDiscountingGraph(item, index, "metrics")
                      : createDiscountingModelGraph(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.discountingMatricsIndex === index
                    }
                    onClick={() => {
                      inputDashboardDiscountingMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) =>
                (inputDashboardDiscountingDefinitionRef.current[index] = el)
              }
              onChange={(e) => handleDiscountingChange(e.target.value, item, 2)}
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "DiscountingDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateDiscountingGraph(item, index, "defination")
                      : createDiscountingModelGraph(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.discountingDefinationIndex === index
                    }
                    onClick={() => {
                      inputDashboardDiscountingDefinitionRef.current[
                        index
                      ].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphDiscountingDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //packagemodel - marketview

  const [dataSource4, setDataSource4] = useState([]);
  const columns4 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) => (inputPackagMarketMetricsRef.current[index] = el)}
            onChange={(e) =>
              handlePackagMarketViewChange(e.target.value, item, 1)
            }
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePackageMarket(item, index, "metrics")
                      : createPackageMarketView(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.packageMatricsMarketIndex === index
                    }
                    onClick={() => {
                      inputPackagMarketMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) => (inputPackagMarketDefinitionRef.current[index] = el)}
              onChange={(e) =>
                handlePackagMarketViewChange(e.target.value, item, 2)
              }
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "PackageMarketDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePackageMarket(item, index, "defination")
                      : createPackageMarketView(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.packageDefinationMarketIndex === index
                    }
                    onClick={() => {
                      inputPackagMarketDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphPackageMarketDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //packagemodel - competitors view
  const [dataSource5, setDataSource5] = useState([]);
  const columns5 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) => (inputPackagMarketMetricsRef.current[index] = el)}
            onChange={(e) =>
              handlePackagCompetitorChange(e.target.value, item, 1)
            }
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePackageCompetitor(item, index, "metrics")
                      : createPackagecompetitorView(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.packageMatricsCompanyIndex === index
                    }
                    onClick={() => {
                      inputPackagMarketMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) => (inputPackagMarketDefinitionRef.current[index] = el)}
              onChange={(e) =>
                handlePackagCompetitorChange(e.target.value, item, 2)
              }
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "PackageCompanyDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePackageCompetitor(item, index, "defination")
                      : createPackagecompetitorView(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.packageDefinationCompanyIndex === index
                    }
                    onClick={() => {
                      inputPackagMarketDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphPackagecompetitorDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //pricing - market view
  const [dataSource6, setDataSource6] = useState([]);
  const columns6 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) => (inputPricingMarketMetricsRef.current[index] = el)}
            onChange={(e) => handlePricingMarketChange(e.target.value, item, 1)}
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePricingMarket(item, index, "metrics")
                      : createPricingMarketView(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.pricingMatricsMarketIndex === index
                    }
                    onClick={() => {
                      inputPricingMarketMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) =>
                (inputPricingMarketDefinitionRef.current[index] = el)
              }
              onChange={(e) =>
                handlePricingMarketChange(e.target.value, item, 2)
              }
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "PricingMarketDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePricingMarket(item, index, "defination")
                      : createPricingMarketView(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.pricingDefinationMarketIndex === index
                    }
                    onClick={() => {
                      inputPricingMarketDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphPricingMarketDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //pricing - company view
  const [dataSource7, setDataSource7] = useState([]);
  const columns7 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) => (inputPricingMarketMetricsRef.current[index] = el)}
            onChange={(e) =>
              handlePricingCompetitorChange(e.target.value, item, 1)
            }
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePricingCompetitor(item, index, "metrics")
                      : createPricingcompetitorView(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading && mainIndex?.pricingMatricsCompanyIndex === index
                    }
                    onClick={() => {
                      inputPricingMarketMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) =>
                (inputPricingMarketDefinitionRef.current[index] = el)
              }
              onChange={(e) =>
                handlePricingCompetitorChange(e.target.value, item, 2)
              }
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() => toggleReadMore(index, "PricingCompanyDefination")}
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updatePricingCompetitor(item, index, "defination")
                      : createPricingcompetitorView(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.pricingDefinationCompanytIndex === index
                    }
                    onClick={() => {
                      inputPricingMarketDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphPricingcompetitorDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //discounting market view

  const [dataSource8, setDataSource8] = useState([]);
  const columns8 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) => (inputDiscountingMarketMetricsRef.current[index] = el)}
            onChange={(e) =>
              handleDiscountingMarketChange(e.target.value, item, 1)
            }
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateDiscountingMarket(item, index, "metrics")
                      : createDiscountingMarketView(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.discountingMatricsMarketIndex === index
                    }
                    onClick={() => {
                      inputDiscountingMarketMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) =>
                (inputDiscountingMarketDefinitionRef.current[index] = el)
              }
              onChange={(e) =>
                handleDiscountingMarketChange(e.target.value, item, 2)
              }
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() =>
                toggleReadMore(index, "DiscountingMarketDefination")
              }
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateDiscountingMarket(item, index, "defination")
                      : createDiscountingMarketView(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.discountingDefinationMarketIndex === index
                    }
                    onClick={() => {
                      inputDiscountingMarketDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() => DeleteGraphDiscountingMarketDefinition(record)}
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];

  //discounting company view
  const [dataSource9, setDataSource9] = useState([]);
  const columns9 = [
    {
      title: "Metric Used",
      dataIndex: "graphql_metric",
      key: "name",
      width: "30%",
      render: (value, item, index) => (
        <div className="main-div">
          <TextArea
            value={item?.graphql_metric}
            style={{
              border: "none",
              fontSize: 15,
              color: "black",
              background: "transparent",
              cursor: !groups.includes("admin") ? "default" : "",
            }}
            ref={(el) => (inputDiscountingMarketMetricsRef.current[index] = el)}
            onChange={(e) =>
              handleDiscountingCompetitorChange(e.target.value, item, 1)
            }
            autoSize
            disabled={!groups.includes("admin")}
          />
          <div>
            {groups.includes("admin") ? (
              item?.isEdit1 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateDiscountingCompetitor(item, index, "metrics")
                      : createDiscountingCompetitor(item, index, "metrics");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Metric">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.discountingMatricsCompanyIndex === index
                    }
                    onClick={() => {
                      inputDiscountingMarketMetricsRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    {
      title: "Definitions",
      dataIndex: "graphql_description",
      key: "definitions",
      width: "40%",
      render: (value, item, index) => (
        <div className="main-div">
          <div className="wb-100">
            <TextArea
              value={
                item?.isShowMore
                  ? item?.graphql_description
                  : item?.graphql_description.slice(0, 130)
              }
              style={{
                border: "none",
                fontSize: 15,
                color: "black",
                background: "transparent",
                cursor: !groups.includes("admin") ? "default" : "",
              }}
              ref={(el) =>
                (inputDiscountingMarketDefinitionRef.current[index] = el)
              }
              onChange={(e) =>
                handleDiscountingCompetitorChange(e.target.value, item, 2)
              }
              autoSize
              disabled={!groups.includes("admin")}
            />

            <span
              onClick={() =>
                toggleReadMore(index, "DiscountingCompanyDefination")
              }
              style={{ color: "#416B88", cursor: "pointer", fontWeight: 600 }}
            >
              {typeof item?.id !== "number"
                ? item?.isShowMore
                  ? "show less"
                  : "...read more"
                : null}
            </span>
          </div>
          <div>
            {groups.includes("admin") ? (
              item?.isEdit2 === true ? (
                <Button
                  style={{ marginLeft: 10, border: "none" }}
                  onClick={() => {
                    typeof item?.id === "string"
                      ? updateDiscountingCompetitor(item, index, "defination")
                      : createDiscountingCompetitor(item, index, "defination");
                  }}
                  icon={
                    <CheckOutlined
                      style={{ cursor: "pointer", color: "green" }}
                    />
                  }
                ></Button>
              ) : (
                <Tooltip placement="top" title="Edit Definitions">
                  <Button
                    className="editIcon"
                    loading={
                      loading &&
                      mainIndex?.discountingDefinationCompanyIndex === index
                    }
                    onClick={() => {
                      inputDiscountingMarketDefinitionRef.current[index].focus({
                        cursor: "end",
                      });
                    }}
                    icon={<EditFilled className="pencil-style" />}
                  ></Button>
                </Tooltip>
              )
            ) : null}
          </div>
        </div>
      ),
    },
    ...(groups.includes("admin")
      ? [
          {
            title: "Last Updated",
            dataIndex: "updatedAt",
            key: "lastupdate",
            width: "25%",
            render: (modifyDate) => (
              <span style={{ fontSize: 16 }}>
                {modifyDate !== undefined && (
                  <Moment date={modifyDate} format="lll" />
                )}
              </span>
            ),
          },
        ]
      : []),
    ...(groups.includes("admin")
      ? [
          {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "15%",
            render: (_, record) => (
              <Popconfirm
                title="Sure to delete?"
                placement="topLeft"
                onConfirm={() =>
                  DeleteGraphDiscountingcompetitorDefinition(record)
                }
              >
                <Tooltip placement="top" title="Delete Row">
                  <DeleteFilled
                    style={{
                      color: "#416B88",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  />
                </Tooltip>
              </Popconfirm>
            ),
          },
        ]
      : []),
  ];
  return (
    <div>
      <div
        style={{
          width: "100%",
          background: "white",
          borderRadius: "8px",
          marginBottom: 20,
        }}
      >
        <div className="guide-data">
          {/* <div className="desc-css">
            <span className="text-desc">
              User Guide To Metrics Used In All Graphs
            </span>
          </div> */}
          {/*need for future */}
          {/* <div className="search-div">
            <Search
              className="searchbar"
              placeholder="Search definition"
              name="search_value"
              id="search_value"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div> */}
        </div>
        {/* dashboard */}
        <div
          className={
            location?.state?.dashboardData && dataSource1?.length > 0
              ? "table-animate"
              : ""
          }
        >
            <>
              <div className="guide-data">
                <div className="dasboardData-text">
                  <span>Pricing Trends Dashboard</span>
                </div>
              </div>

              <>
                <div>
                  <Tabs className="tabfont tab-clss" centered type="card">
                    <>
                      <TabPane
                        tab={
                          <h3
                            className="font-family-style"
                            style={{
                              marginBottom: 0,
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          >
                            Packaging Models
                          </h3>
                        }
                        key="1"
                      >
                        {/* PACKAGE MODEL*/}
                        <div
                          className={
                            location?.state?.dashboardData &&
                            dataSource1?.length > 0
                              ? "table-animate"
                              : ""
                          }
                        >
                          
                            <>
                              <div className="guide-data">
                                <div
                                  className="dasboard-text"
                                  style={{ marginTop: 20 }}
                                >
                                </div>
                                {groups.includes("admin") && (
                                  <div className="plus-btn">
                                    <Tooltip
                                      placement="leftTop"
                                      title="Add Row"
                                    >
                                      <Button
                                        className="btn-clr"
                                        onClick={handleAddPackage}
                                        icon={<PlusOutlined />}
                                      ></Button>
                                    </Tooltip>
                                  </div>
                                )}
                              </div>
                              <div style={{ paddingBottom: "20px" }}>
                               
                                  {tableLoader ? (
                                    <div
                                      style={{
                                        background: "#fff",
                                        padding: 20,
                                      }}
                                    >
                                      <Skeleton
                                        active
                                        paragraph={{ rows: 7 }}
                                      />{" "}
                                    </div>
                                  ) : (
                                    <Table
                                      dataSource={allDataSource1}
                                      columns={columns1}
                                      pagination={false}
                                      bordered
                                      className="table1"
                                    />
                                  )}
                              </div>{" "}
                            </>
                          
                        </div>
                      </TabPane>
                    </>

                    <TabPane
                      tab={
                        <h3
                          className="font-family-style"
                          style={{
                            marginBottom: 0,
                            fontWeight: 600,
                            fontSize: 18,
                          }}
                        >
                          Pricing Models
                        </h3>
                      }
                      key="2"
                    >
                      {/*PRICING MODEL */}
                      <div
                        className={
                          location?.state?.PricingData &&
                          dataSource2?.length > 0
                            ? "table-animate"
                            : ""
                        }
                      >
                       
                          <>
                            <div className="guide-data">
                              <div
                                className="dasboard-text"
                                style={{ marginTop: 20 }}
                              >
                              </div>
                              {groups.includes("admin") && (
                                <div className="plus-btn">
                                  <Tooltip placement="leftTop" title="Add Row">
                                    <Button
                                      className="btn-clr"
                                      onClick={handleAddPricing}
                                      icon={<PlusOutlined />}
                                    ></Button>
                                  </Tooltip>
                                </div>
                              )}
                            </div>

                            <div style={{ paddingBottom: "20px" }}>
                              <Table
                                dataSource={allDataSource2}
                                columns={columns2}
                                pagination={false}
                                bordered
                                className="table1"
                              />
                            </div>
                          </>
                        
                      </div>
                    </TabPane>
                    <TabPane
                      tab={
                        <h3
                          className="font-family-style"
                          style={{
                            marginBottom: 0,
                            fontWeight: 600,
                            fontSize: 18,
                          }}
                        >
                          Pricing & Discounting Models
                        </h3>
                      }
                      key="3"
                    >
                      {/*PRICING and DISCOUNTING MODEL */}
                      <div
                        className={
                          location?.state?.Discounting &&
                          dataSource3?.length > 0
                            ? "table-animate"
                            : ""
                        }
                      >
                       
                          <>
                            <div className="guide-data">
                              <div
                                className="dasboard-text"
                                style={{ marginTop: 20 }}
                              >
                              </div>
                              {groups.includes("admin") && (
                                <div className="plus-btn">
                                  <Tooltip placement="leftTop" title="Add Row">
                                    <Button
                                      className="btn-clr"
                                      onClick={handleAddDiscounting}
                                      icon={<PlusOutlined />}
                                    ></Button>
                                  </Tooltip>
                                </div>
                              )}
                            </div>

                            <div style={{ paddingBottom: "20px" }}>
                              <Table
                                dataSource={allDataSource3}
                                columns={columns3}
                                pagination={false}
                                bordered
                                className="table1"
                              />
                            </div>
                          </>
                        
                      </div>
                    </TabPane>
                  </Tabs>
                </div>
              </>
            </>
       
        </div>
      </div>

      {/* Analytics */}
      <div
        style={{
          width: "100%",
          background: "white",
          borderRadius: "8px",
        }}
      >
        <div
          className={
            location?.state?.PackageData && dataSource4?.length > 0
              ? "table-animate"
              : ""
          }
        >
          {accountDetails?.accountStatus !== "FREE_TRIAL_END" && (
            <>
              <div className="guide-data">
                <div className="dasboard-text" style={{ marginTop: 24 }}>
                  <span>Pricing Deep Dives</span>
                </div>
              </div>

              <>
                <div >
                  {/* temporary commented*/}
                  {/* <Tabs className="tabfont" centered> */}
                    <>
                      {/*Packaging Model tab */}
                      {/* <TabPane
                        tab={
                          <h3
                            className="font-family-style"
                            style={{
                              marginBottom: 0,
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          >
                            Packaging Model
                          </h3>
                        }
                        key="1"
                      > */}
                        <Tabs className="tabfont tabb-clss" centered type="card">
                          <TabPane
                            tab={
                              <h3
                                className="font-family-style"
                                style={{
                                  marginBottom: 0,
                                  fontWeight: 600,
                                  fontSize: 18,
                                }}
                              >
                                Market View
                              </h3>
                            }
                            key="1"
                          >
                           
                            <div
                              className={
                                location?.state?.PackageData &&
                                dataSource4?.length > 0
                                  ? "table-animate"
                                  : ""
                              }
                            >
                              {accountDetails?.accountStatus !==
                                "FREE_TRIAL_END" && (
                                <>
                                  <div className="guide-data">
                                    <div
                                      
                                      style={{ marginTop: 20 }}
                                    ><span className="text-define">Packaging Models</span></div>
                                    {groups.includes("admin") && (
                                      <div className="plus-btn">
                                        <Tooltip
                                          placement="leftTop"
                                          title="Add Row"
                                        >
                                          <Button
                                            className="btn-clr"
                                            onClick={handleAddPackageMarket}
                                            icon={<PlusOutlined />}
                                          ></Button>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                  <div style={{ paddingBottom: "20px" }}>
                              
                                    {accountDetails?.accountStatus !==
                                      "FREE_TRIAL_END" &&
                                      (tableLoader ? (
                                        <div
                                          style={{
                                            background: "#fff",
                                            padding: 20,
                                          }}
                                        >
                                          <Skeleton
                                            active
                                            paragraph={{ rows: 7 }}
                                          />{" "}
                                        </div>
                                      ) : (
                                        <Table
                                          dataSource={allDataSource4}
                                          columns={columns4}
                                          pagination={false}
                                          bordered
                                          className="table1"
                                        />
                                      ))}
                                  </div>{" "}
                                </>
                              )}
                            </div>
                           
                            <div
                              className={
                                location?.state?.PackageData &&
                                dataSource6?.length > 0
                                  ? "table-animate"
                                  : ""
                              }
                            >
                           
                              {accountDetails?.accountStatus !==
                                "FREE_TRIAL_END" && (
                                <>
                                  <div className="guide-data">
                                    <div
                                     
                                      style={{ marginTop: 20 }}
                                    > <span className="text-define">Pricing Models</span></div>
                                    {groups.includes("admin") && (
                                      <div className="plus-btn">
                                        <Tooltip
                                          placement="leftTop"
                                          title="Add Row"
                                        >
                                          <Button
                                            className="btn-clr"
                                            onClick={handleAddPricingMarket}
                                            icon={<PlusOutlined />}
                                          ></Button>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                  <div style={{ paddingBottom: "20px" }}>
                                    {accountDetails?.accountStatus !==
                                      "FREE_TRIAL_END" && (
                                      <Table
                                        dataSource={allDataSource6}
                                        columns={columns6}
                                        pagination={false}
                                        bordered
                                        className="table1"
                                      />
                                    )}
                                  </div>{" "}
                                </>
                              )}
                            </div>
                           
                            <div
                            className={
                              location?.state?.PackageData &&
                              dataSource8?.length > 0
                                ? "table-animate"
                                : ""
                            }
                          >
                            {accountDetails?.accountStatus !==
                              "FREE_TRIAL_END" && (
                              <>
                                <div className="guide-data">
                                  <div
                                  
                                    style={{ marginTop: 20 }}
                                  > <span className="text-define">Pricing & Discounting Models</span></div>
                                  {groups.includes("admin") && (
                                    <div className="plus-btn">
                                      <Tooltip
                                        placement="leftTop"
                                        title="Add Row"
                                      >
                                        <Button
                                          className="btn-clr"
                                          onClick={handleAddDiscountingMarket}
                                          icon={<PlusOutlined />}
                                        ></Button>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                                <div style={{ paddingBottom: "20px" }}>
                                  {accountDetails?.accountStatus !==
                                    "FREE_TRIAL_END" && (
                                    <Table
                                      dataSource={allDataSource8}
                                      columns={columns8}
                                      pagination={false}
                                      bordered
                                      className="table1"
                                    />
                                  )}
                                </div>{" "}
                              </>
                            )}
                          </div>
                          </TabPane>
                          <TabPane
                            tab={
                              <h3
                                className="font-family-style"
                                style={{
                                  marginBottom: 0,
                                  fontWeight: 600,
                                  fontSize: 18,
                                }}
                              >
                                Company View
                              </h3>
                            }
                            key="2"
                          >
                           
                            <div
                              className={
                                location?.state?.PackageData &&
                                dataSource5?.length > 0
                                  ? "table-animate"
                                  : ""
                              }
                            >
                              {accountDetails?.accountStatus !==
                                "FREE_TRIAL_END" && (
                                <>
                                  <div className="guide-data">
                                    <div
                                     
                                      style={{ marginTop: 20 }}
                                    > <span className="text-define">Packaging Models</span></div>
                                    {groups.includes("admin") && (
                                      <div className="plus-btn">
                                        <Tooltip
                                          placement="leftTop"
                                          title="Add Row"
                                        >
                                          <Button
                                            className="btn-clr"
                                            onClick={handleAddPackageCompetitor}
                                            icon={<PlusOutlined />}
                                          ></Button>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                  <div style={{ paddingBottom: "20px" }}>
                                    {accountDetails?.accountStatus !==
                                      "FREE_TRIAL_END" && (
                                      <Table
                                        dataSource={allDataSource5}
                                        columns={columns5}
                                        pagination={false}
                                        bordered
                                        className="table1"
                                      />
                                    )}
                                  </div>{" "}
                                </>
                              )}
                            </div>
                           
                            <div
                              className={
                                location?.state?.PackageData &&
                                dataSource7?.length > 0
                                  ? "table-animate"
                                  : ""
                              }
                            >
                              {accountDetails?.accountStatus !==
                                "FREE_TRIAL_END" && (
                                <>
                                  <div className="guide-data">
                                    <div
                                     
                                      style={{ marginTop: 20 }}
                                    > <span className="text-define">Pricing Models</span></div>
                                    {groups.includes("admin") && (
                                      <div className="plus-btn">
                                        <Tooltip
                                          placement="leftTop"
                                          title="Add Row"
                                        >
                                          <Button
                                            className="btn-clr"
                                            onClick={handleAddPricingCompetitor}
                                            icon={<PlusOutlined />}
                                          ></Button>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                  <div style={{ paddingBottom: "20px" }}>
                                    {accountDetails?.accountStatus !==
                                      "FREE_TRIAL_END" && (
                                      <Table
                                        dataSource={allDataSource7}
                                        columns={columns7}
                                        pagination={false}
                                        bordered
                                        className="table1"
                                      />
                                    )}
                                  </div>{" "}
                                </>
                              )}
                            </div>
                          
                            <div
                            className={
                              location?.state?.PackageData &&
                              dataSource9?.length > 0
                                ? "table-animate"
                                : ""
                            }
                          >
                            {accountDetails?.accountStatus !==
                              "FREE_TRIAL_END" && (
                              <>
                                <div className="guide-data">
                                  <div
                                  
                                    style={{ marginTop: 20 }}
                                  >  <span className="text-define">Pricing & Discounting Models</span></div>
                                  {groups.includes("admin") && (
                                    <div className="plus-btn">
                                      <Tooltip
                                        placement="leftTop"
                                        title="Add Row"
                                      >
                                        <Button
                                          className="btn-clr"
                                          onClick={
                                            handleAddDiscountingCompetitor
                                          }
                                          icon={<PlusOutlined />}
                                        ></Button>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                                <div style={{ paddingBottom: "20px" }}>
                                  {accountDetails?.accountStatus !==
                                    "FREE_TRIAL_END" && (
                                    <Table
                                      dataSource={allDataSource9}
                                      columns={columns9}
                                      pagination={false}
                                      bordered
                                      className="table1"
                                    />
                                  )}
                                </div>{" "}
                              </>
                            )}
                          </div>
                          </TabPane>
                        </Tabs>
                      {/* </TabPane> */}
                    </>

                    <>
                      {/*Pricing Model tab */}
                      <TabPane
                        tab={
                          <h3
                            className="font-family-style"
                            style={{
                              marginBottom: 0,
                              fontWeight: 600,
                              fontSize: 18,
                            }}
                          >
                            Pricing Model
                          </h3>
                        }
                        key="2"
                      >
                        <Tabs className="tabfont tab-clss" centered type="card">
                          <TabPane
                            tab={
                              <h3
                                className="font-family-style"
                                style={{
                                  marginBottom: 0,
                                  fontWeight: 600,
                                  fontSize: 18,
                                }}
                              >
                                Market View
                              </h3>
                            }
                            key="1"
                          >

                            <div
                              className={
                                location?.state?.PackageData &&
                                dataSource6?.length > 0
                                  ? "table-animate"
                                  : ""
                              }
                            >
                           
                              {accountDetails?.accountStatus !==
                                "FREE_TRIAL_END" && (
                                <>
                                  <div className="guide-data">
                                    <div
                                      className="dasboard-text"
                                      style={{ marginTop: 20 }}
                                    ></div>
                                    {groups.includes("admin") && (
                                      <div className="plus-btn">
                                        <Tooltip
                                          placement="leftTop"
                                          title="Add Row"
                                        >
                                          <Button
                                            className="btn-clr"
                                            onClick={handleAddPricingMarket}
                                            icon={<PlusOutlined />}
                                          ></Button>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                  <div style={{ paddingBottom: "20px" }}>
                                    {accountDetails?.accountStatus !==
                                      "FREE_TRIAL_END" && (
                                      <Table
                                        dataSource={allDataSource6}
                                        columns={columns6}
                                        pagination={false}
                                        bordered
                                        className="table1"
                                      />
                                    )}
                                  </div>{" "}
                                </>
                              )}
                            </div>
                          </TabPane>
                          <TabPane
                            tab={
                              <h3
                                className="font-family-style"
                                style={{
                                  marginBottom: 0,
                                  fontWeight: 600,
                                  fontSize: 18,
                                }}
                              >
                                Company View
                              </h3>
                            }
                            key="2"
                          >
                            <div
                              className={
                                location?.state?.PackageData &&
                                dataSource7?.length > 0
                                  ? "table-animate"
                                  : ""
                              }
                            >
                              {accountDetails?.accountStatus !==
                                "FREE_TRIAL_END" && (
                                <>
                                  <div className="guide-data">
                                    <div
                                      className="dasboard-text"
                                      style={{ marginTop: 20 }}
                                    ></div>
                                    {groups.includes("admin") && (
                                      <div className="plus-btn">
                                        <Tooltip
                                          placement="leftTop"
                                          title="Add Row"
                                        >
                                          <Button
                                            className="btn-clr"
                                            onClick={handleAddPricingCompetitor}
                                            icon={<PlusOutlined />}
                                          ></Button>
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                  <div style={{ paddingBottom: "20px" }}>
                                    {accountDetails?.accountStatus !==
                                      "FREE_TRIAL_END" && (
                                      <Table
                                        dataSource={allDataSource7}
                                        columns={columns7}
                                        pagination={false}
                                        bordered
                                        className="table1"
                                      />
                                    )}
                                  </div>{" "}
                                </>
                              )}
                            </div>
                          </TabPane>
                        </Tabs>
                      </TabPane>
                    </>
                    {/*Pricing & Discounting Model tab */}
                    <TabPane
                      tab={
                        <h3
                          className="font-family-style"
                          style={{
                            marginBottom: 0,
                            fontWeight: 600,
                            fontSize: 18,
                          }}
                        >
                          Pricing & Discounting
                        </h3>
                      }
                      key="3"
                    >
                      <Tabs className="tabfont tab-clss" centered type="card">
                        <TabPane
                          tab={
                            <h3
                              className="font-family-style"
                              style={{
                                marginBottom: 0,
                                fontWeight: 600,
                                fontSize: 18,
                              }}
                            >
                              Market View
                            </h3>
                          }
                          key="1"
                        >
                          <div
                            className={
                              location?.state?.PackageData &&
                              dataSource8?.length > 0
                                ? "table-animate"
                                : ""
                            }
                          >
                            {accountDetails?.accountStatus !==
                              "FREE_TRIAL_END" && (
                              <>
                                <div className="guide-data">
                                  <div
                                    className="dasboard-text"
                                    style={{ marginTop: 20 }}
                                  ></div>
                                  {groups.includes("admin") && (
                                    <div className="plus-btn">
                                      <Tooltip
                                        placement="leftTop"
                                        title="Add Row"
                                      >
                                        <Button
                                          className="btn-clr"
                                          onClick={handleAddDiscountingMarket}
                                          icon={<PlusOutlined />}
                                        ></Button>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                                <div style={{ paddingBottom: "20px" }}>
                                  {accountDetails?.accountStatus !==
                                    "FREE_TRIAL_END" && (
                                    <Table
                                      dataSource={allDataSource8}
                                      columns={columns8}
                                      pagination={false}
                                      bordered
                                      className="table1"
                                    />
                                  )}
                                </div>{" "}
                              </>
                            )}
                          </div>
                        </TabPane>
                        <TabPane
                          tab={
                            <h3
                              className="font-family-style"
                              style={{
                                marginBottom: 0,
                                fontWeight: 600,
                                fontSize: 18,
                              }}
                            >
                              Company View
                            </h3>
                          }
                          key="2"
                        >
                          <div
                            className={
                              location?.state?.PackageData &&
                              dataSource9?.length > 0
                                ? "table-animate"
                                : ""
                            }
                          >
                            {accountDetails?.accountStatus !==
                              "FREE_TRIAL_END" && (
                              <>
                                <div className="guide-data">
                                  <div
                                    className="dasboard-text"
                                    style={{ marginTop: 20 }}
                                  ></div>
                                  {groups.includes("admin") && (
                                    <div className="plus-btn">
                                      <Tooltip
                                        placement="leftTop"
                                        title="Add Row"
                                      >
                                        <Button
                                          className="btn-clr"
                                          onClick={
                                            handleAddDiscountingCompetitor
                                          }
                                          icon={<PlusOutlined />}
                                        ></Button>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                                <div style={{ paddingBottom: "20px" }}>
                                  {accountDetails?.accountStatus !==
                                    "FREE_TRIAL_END" && (
                                    <Table
                                      dataSource={allDataSource9}
                                      columns={columns9}
                                      pagination={false}
                                      bordered
                                      className="table1"
                                    />
                                  )}
                                </div>{" "}
                              </>
                            )}
                          </div>
                        </TabPane>
                      </Tabs>
                    </TabPane>
                  {/* </Tabs> */}
                </div>
              </>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default GraphDefinition;
