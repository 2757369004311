/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Form,
  Input,
  Row,
  Col,
  Select,
  Button,
  message,
  Skeleton,
} from "antd";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setAccountDetails } from "../store/action/loginAction";
import { updateAccount } from "../graphql/mutations";
import "./company-detail.css";
import sdk from "../sdk/Accounts";
function CompanyDetailFill(props) {
  // eslint-disable-next-line
  const [segments, setSegments] = useState([]);
  const [dropDownLoader, setDropdownLoader] = useState(false);
  // eslint-disable-next-line
  const [segmentLoader, setSegmentLoader] = useState(false);
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [form] = Form.useForm();
  // eslint-disable-next-line
  const [categoryValue, setCategoryValue] = useState("");
  // eslint-disable-next-line
  const [segmentValue, setSegmentValue] = useState("");
  // eslint-disable-next-line
  const [employeeValue, setEmployeeValue] = useState("");
  const [competitors, setCompetitors] = useState([]);
  const dispatch = useDispatch();
  const { Option } = Select;
  const { accountDetails, groups } = useSelector((state) => state.loginReducer);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [segment, setSegment] = useState(true);
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const history = useHistory();
  const [detailLoading,setDetailLoading] = useState(false)
  // May be needed in future
  const getCategory = async (data) => {
    setSegmentLoader(true);

    let apiURLData = `/redshiftDataManager/getCategories`;
    await API.get("XaaSPricingREST", apiURLData)
      .then((response) => {
        let data = response.map((item) => item[1]);
        let data1 = [...new Set(data)];
        setData(data1.sort());
        setSegmentLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setSegmentLoader(false);
      });
  };
  const getPrimarySegment = async (data) => {
    setDropdownLoader(true);
    let apiURLData = `/redshiftDataManager/getPrimarySegments`;
    await API.get("XaaSPricingREST", apiURLData)
      .then((response) => {
        let data = response.map((item) => item[0]);
        setSegments(data);
        setDropdownLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setDropdownLoader(false);
      });
  };
  // May be needed in future
  const getTotalSegments = async (data) => {
    let apiURLData = `/redshiftDataManager/getTotalEmployeeRange`;
    await API.get("XaaSPricingREST", apiURLData)
      .then((response) => {
        // let data = response.map(item => item[0])
        // setEmployeeSize(data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSegmentCompetitors = async (category) => {
    setDetailLoading(true)
    setCategoryLoader(true);
    //need for future
    // let categoryValue1 = form.getFieldsValue();
    // if (category !== undefined) {
    //   let apiURLData = `/redshiftDataManager/getCompetitors?category=${category}`;
    //   await API.get("XaaSPricingREST", apiURLData)
    //     .then((response) => {
    //       let temp = [];
    //       response.map((item) => {
    //         let abc = {
    //           id: item[1],
    //           company_name: item[0],
    //         };
    //         temp.push(abc);
    //       });
    //       setCompetitors(temp);
    //       setCategoryLoader(false);
    //     })
    //     .catch((err) => {
    //       setCategoryLoader(false);
    //     });
    // } 
   
      let apiURLData = `/redshiftDataManager/getCompetitors`;
      await API.get("XaaSPricingREST", apiURLData)
        .then((response) => {
          let temp = [];
          response.map((item) => {
            let abc = {
              id: item[1],
              company_name: item[0],
            };
            temp.push(abc);
            return temp;
          });
          setCompetitors(temp);
          setCategoryLoader(false);
          setDetailLoading(false)

          //need for future
          // if (response?.length === 0) {
          //   message.info("No segment competitor found for this category");
          //   setCategoryLoader(false);
          // }
        })
        .catch((err) => {
          setCategoryLoader(false);
          setDetailLoading(false)

        });
    
  };

  // May be needed in future
  // const handleCategory = (value) => {
  //   dispatch(setDropdown(value))
  //   setCategoryValue(value)
  // }

  //nedd in future
  // const handlePrimarySegment = (value) => {
  //   setSegment(false);
  //   form.setFieldsValue({ segmentcompetitor: [] });
  //   setSegmentValue(value);
  //   getCategory();
  //   getSegmentCompetitors();
  // };
  const handleALL = (e) => {
    let data = e.filter((item) => item === "All");
    if (data.length > 0) {
      let temp = [];
      
      competitors.map((item) => {
        temp.push(item?.company_name);
        return temp
      });
      form.setFieldsValue({ segmentcompetitor: temp });
    }

  };
  const handleSubmit = async () => {
    setLoading(true);
    let temp = [];
    let data = form.getFieldsValue();
    data?.segmentcompetitor.map((item) => {
      competitors.map((value) => {
        if (item === value.company_name) {
          temp.push(value);
        }
      });
    });
    try {
      let request = await API.graphql(
        graphqlOperation(updateAccount, {
          input: {
            id: accountDetails?.id,
            employee_size: data?.employee_size,
            company_segment: data?.companySegment,
            category: data?.category,
            segment_comapany: JSON.stringify(temp),
            url: data?.url,
            company: data?.company,
          },
        })
      );
      request = request.data.updateAccount;
      dispatch(setAccountDetails(request));
      message.success("Details Submitted Successfully");
      setLoading(false);
      if (accountDetails?.accountStatus !== null) {
        history.push("/details");
      } else {
        history.push("/settings");
      }
      return request;
    } catch (e) {
      setLoading(false);
      console.log(e);
      return { errors: ["The requested account does not exist."] };
    }
  };

  const getAccount = async () => {
    setDetailLoading(true)
    const accountID = groups.length > 0 ? groups[0] : null;
    if (accountID) {
      let account = await sdk.fetchAccount(accountID);
      dispatch(setAccountDetails(account));
    }
    setDetailLoading(false)
  };
  useEffect(() => {
    getAccount();
    getCategory();
    getPrimarySegment();
    getTotalSegments();
   
      getSegmentCompetitors();
    
    let temp = [];
    if(accountDetails?.segment_comapany !== null && accountDetails?.segment_comapany !== undefined ){
      JSON.parse(accountDetails?.segment_comapany).map(item => {
        temp.push(item?.company_name)
        return temp
      })
    }
    form.setFieldsValue({
      companySegment: accountDetails?.company_segment,
       segmentcompetitor: temp,
      // JSON.parse(accountDetails?.segment_comapany?.[0]) !== null
      //     ? accountDetails?.segment_comapany
      //     : [],
      category: accountDetails?.category,
      url: accountDetails?.url === "https://" ? " " : accountDetails?.url,
      company: accountDetails?.company,
    });
    
    // eslint-disable-next-line
  }, []);

  return (
    <>
    {detailLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
         <Skeleton active paragraph={{ rows: 7 }} />{" "}
        </div>
    ):(

      <div
        style={{
          width: "100%",
          background: "white",
          borderRadius: "8px",
          marginBottom: "10px",
          paddingBottom: "15px",
        }}
      >
        <Row justify="center">
          <Col span={8}>
            <Typography.Title
              style={{
                fontSize: 20,
                paddingTop: 30,
                color: "#416B88",
                fontWeight: 600,
                display: "flex",
                justifyContent: "center",
              }}
            >
              Set up your analysis
            </Typography.Title>
            <Form
              initialValues={{ segment_comapany: [] }}
              form={form}
              onFinish={handleSubmit}
              style={{ paddingTop: 15 }}
              layout="vertical"
              requiredMark={false}
            >
              {groups.includes("individual") ? (
                <>
                  {/* need in future*/}
                  {/* <Form.Item label="Select industry">
              <Select  className="option" style={{ height: "35px !important" }}>
                {SelectIndustry.map((value,index) => {
                  return (
                    <Option  key={index} value={value}>{value}</Option>
                  )
                })}
              </Select>
            </Form.Item> */}
                  {/* <Form.Item label="Company type">
              <Select  className="option" style={{ height: "35px !important" }}>
                {CompanyType.map((value,index) => {
                  return (
                    <Option  key={index} value={value}>{value}</Option>
                  )
                })}
              </Select>
            </Form.Item> */}
                  {/* <Form.Item
              label={
                <span style={{ fontSize: 18, color: "#727272" }}>
                  Company Segment
                </span>
              }
              name="companySegment"
            >
              <Select
                onChange={handlePrimarySegment}
                className="option"
                style={{ fontSize: 18 }}
                size="large"
                loading={dropDownLoader}
              >
                {segments.map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
                  <Form.Item
                    label={
                      <span style={{ fontSize: 18, color: "#727272" }}>
                        {" "}
                        Company Name (Optional)
                      </span>
                    }
                    name="company"
                  >
                    <Input
                      disabled={accountDetails?.company?.length > 0}
                      className="option"
                      style={{
                        width: "100%",
                        borderRadius: 7,
                        fontSize: 18,
                        height: 45,
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ fontSize: 18, color: "#727272" }}>
                        {" "}
                        URL (Optional)
                      </span>
                    }
                    name="url"
                  >
                    <Input
                      disabled={
                        accountDetails?.url?.length > 0 &&
                        accountDetails?.url !== "https://" &&
                        accountDetails?.url !== " "
                      }
                      className="option"
                      style={{
                        width: "100%",
                        borderRadius: 7,
                        fontSize: 18,
                        height: 45,
                      }}
                    />
                  </Form.Item>
                  {/* need in future*/}
                  {/* <Form.Item
              label={
                <span style={{ fontSize: 18, color: "#727272" }}>
                  {" "}
                  Company Segment
                </span>
              }
              name="category"
              rules={[
                {
                  required: true,
                  message: 'Company Segment is required',
                },
              ]}
            >
               {dropDownLoader && <div className="loading">Loading;</div> ? (
              <Skeleton.Input active size="large" className="skeleton-loader" />
            ) : ( 
              <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}              
                onChange={handlePrimarySegment}
                className="option segment-modal"
                style={{ width: "100%", borderRadius: 7, fontSize: 18 }}
                size="large"
                loading={segmentLoader}
              >
                {data.map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            )}
            </Form.Item> */}

                  <Form.Item
                  data-tour = "tour__companies"
                    label={
                      <span style={{ fontSize: 18, color: "#727272" }}>
                        Choose Companies to Analyze
                      </span>
                    }
                    name="segmentcompetitor"
                    rules={[
                      {
                        required: competitors?.length > 0 ? true : false,
                        message: "Company is required",
                      },
                    ]}
                  >
                    {dropDownLoader && (
                      <div className="loading">Loading;</div>
                    ) ? (
                      <Skeleton.Input
                        active
                        size="large"
                        className="skeleton-loader"
                      />
                    ) : (
                      <Select
                        showArrow
                        loading={categoryLoader}
                        mode="multiple"
                        onChange={handleALL}
                        className="invitemail"
                        style={{ width: "100%", borderRadius: 7, fontSize: 18 }}
                        size="large"
                      >
                          {competitors?.length > 0 &&
                          [{ id: 0, company_name: "All" }, ...competitors].map(
                            (value, index) => {
                              return (
                                <Option key={index} value={value?.company_name}>
                                  {value?.company_name}
                                </Option>
                              );
                            }
                          )}
                      </Select>
                    )}
                  </Form.Item>
                  {/* need in future*/}
                  {/* <Form.Item label="Employee Size" name="employee_size">
              <Select onChange={handleEmployeeSize} className="option" style={{ height: "35px !important" }}>
              {employee_size.map((value, index) => {
                                return (
                                    <Option
                                        key={index} value={value}>{value}</Option>
                                )
                            })}
              </Select>
            </Form.Item> */}
                  {/* <Form.Item label="Your Job role">
              <Select  className="option" style={{ height: "35px !important" }}>
                {JobRole.map((value, index) => {
                  return (
                    <Option  key={index} value={value}>{value}</Option>
                  )
                })}
              </Select>
            </Form.Item> */}
                  <Form.Item>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      
                      {accountDetails?.accountStatus === null ? (
                        dropDownLoader && (
                          <div className="loading">Loading;</div>
                        ) ? (
                          <Skeleton.Input
                            active
                            size="large"
                            style={{
                              width: "100%",
                              borderRadius: 7,
                              fontSize: 18,
                            }}
                          />
                        ) : (
                          <Button
                            htmlType="submit"
                            style={{
                              backgroundColor: "#416B88",
                              color: "white",
                              height: "auto",
                              width: "auto",
                              borderRadius: "8px",
                              fontWeight: 600,
                              fontSize: "18px",
                              padding: "6px 20px",
                            }}
                            loading={loading}
                          >
                            Save & Next
                          </Button>
                        )
                      ) : dropDownLoader && (
                          <div className="loading">Loading;</div>
                        ) ? (
                        <Skeleton.Input
                          active
                          size="large"
                          style={{
                            width: "100%",
                            borderRadius: 7,
                            fontSize: 18,
                          }}
                        />
                      ) : (
                        <Button
                          htmlType="submit"
                          style={{
                            backgroundColor: "#416B88",
                            color: "white",
                            height: "44px",
                            width: "100px",
                            borderRadius: "8px",
                            fontWeight: 600,
                            fontSize: "18px",
                            padding: "6px 20px",
                          }}
                          loading={loading}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    label={
                      <span style={{ fontSize: 18, color: "#727272" }}>
                        Company Name
                      </span>
                    }
                    style={{ color: "#F5F5F5" }}
                  >
                    {dropDownLoader && (
                      <div className="loading">Loading;</div>
                    ) ? (
                      <Skeleton.Input
                        active
                        size="large"
                        className="skeleton-loader"
                      />
                    ) : (
                      <Input
                        value={accountDetails?.company}
                        style={{
                          borderRadius: "8px",
                          height: "44px",
                          width: "100%",
                          fontSize: 18,
                        }}
                        disabled
                      />
                    )}
                  </Form.Item>

                  <Form.Item
                    label={
                      <span style={{ fontSize: 18, color: "#727272" }}>
                        Company URL
                      </span>
                    }
                  >
                    {dropDownLoader && (
                      <div className="loading">Loading;</div>
                    ) ? (
                      <Skeleton.Input
                        active
                        size="large"
                        className="skeleton-loader"
                      />
                    ) : (
                      <Input
                        value={accountDetails?.url}
                        style={{
                          borderRadius: "8px",
                          height: "44px",
                          width: "100%",
                          fontSize: 18,
                        }}
                        disabled
                      />
                    )}
                  </Form.Item>

                  {/* need in future*/}
                  {/* <Form.Item label="Select industry">
              <Select  className="option" style={{ height: "35px !important" }}>
                {SelectIndustry.map((value,index) => {
                  return (
                    <Option  key={index} value={value}>{value}</Option>
                  )
                })}
              </Select>
            </Form.Item> */}
                  {/* <Form.Item label="Company type">
              <Select  className="option" style={{ height: "35px !important" }}>
                {CompanyType.map((value,index) => {
                  return (
                    <Option  key={index} value={value}>{value}</Option>
                  )
                })}
              </Select>
            </Form.Item> */}
                  {/* <Form.Item
              label={
                <span style={{ fontSize: 18, color: "#727272" }}>
                  Company Segment
                </span>
              }
              name="companySegment"
            >
              <Select
                onChange={handlePrimarySegment}
                className="option"
                style={{ fontSize: 18 }}
                size="large"
                loading={dropDownLoader}
              >
                {segments.map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item> */}
                  {/* <Form.Item
              label={
                <span style={{ fontSize: 18, color: "#727272" }}>
                  {" "}
                  Company Segment
                </span>
              }
              name="category"
              rules={[
                {
                  required: true,
                  message: 'Company Segment is required',
                },
              ]}
            >
               {dropDownLoader && <div className="loading">Loading;</div> ? (
              <Skeleton.Input active size="large" className="skeleton-loader" />
            ) : ( 
              <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                onChange={handlePrimarySegment}
                className="option segment-modal"
                style={{ width: "100%", borderRadius: 7, fontSize: 18 }}
                size="large"
                loading={dropDownLoader}
              >
                {data.map((value, index) => {
                  return (
                    <Option key={index} value={value}>
                      {value}
                    </Option>
                  );
                })}
              </Select>
            )}
            </Form.Item> */}

                  <Form.Item
                  data-tour = "tour__companies"
                    label={
                      <span style={{ fontSize: 18, color: "#727272" }}>
                         Choose Companies to Analyze
                      </span>
                    }
                    name="segmentcompetitor"
                    rules={[
                      {
                        required: competitors?.length > 0 ? true : false,
                        message: "Company is required",
                      },
                    ]}
                  >
                    {dropDownLoader && (
                      <div className="loading">Loading;</div>
                    ) ? (
                      <Skeleton.Input
                        active
                        size="large"
                        className="skeleton-loader"
                      />
                    ) : (
                      <Select
                        showArrow
                        loading={categoryLoader}
                        mode="multiple"
                        allowClear
                        onChange={handleALL}
                        className="invitemail"
                        style={{ width: "100%", borderRadius: 7, fontSize: 18 }}
                        size="large"
                      >
                        {competitors?.length > 0 &&
                          [{ id: 0, company_name: "All" }, ...competitors].map(
                            (value, index) => {
                              return (
                                <Option key={index} value={value?.company_name}>
                                  {value?.company_name}
                                </Option>
                              );
                            }
                          )}
                      </Select>
                    )}
                  </Form.Item>
                  {/* need in future*/}
                  {/* <Form.Item label="Employee Size" name="employee_size">
              <Select onChange={handleEmployeeSize} className="option" style={{ height: "35px !important" }}>
              {employee_size.map((value, index) => {
                                return (
                                    <Option
                                        key={index} value={value}>{value}</Option>
                                )
                            })}
              </Select>
            </Form.Item> */}
                  {/* <Form.Item label="Your Job role">
              <Select  className="option" style={{ height: "35px !important" }}>
                {JobRole.map((value, index) => {
                  return (
                    <Option  key={index} value={value}>{value}</Option>
                  )
                })}
              </Select>
            </Form.Item> */}

                  <Form.Item>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {accountDetails?.accountStatus === null ? (
                        dropDownLoader && (
                          <div className="loading">Loading;</div>
                        ) ? (
                          <Skeleton.Input
                            active
                            size="large"
                            style={{
                              width: "100%",
                              borderRadius: 7,
                              fontSize: 18,
                            }}
                          />
                        ) : (
                          <Button
                            htmlType="submit"
                            style={{
                              backgroundColor: "#416B88",
                              color: "white",
                              height: "auto",
                              width: "auto",
                              borderRadius: "8px",
                              fontWeight: 600,
                              fontSize: "18px",
                              padding: "6px 20px",
                            }}
                            loading={loading}
                          >
                            Save & Next
                          </Button>
                        )
                      ) : dropDownLoader && (
                          <div className="loading">Loading;</div>
                        ) ? (
                        <Skeleton.Input
                          active
                          size="large"
                          style={{
                            width: "100%",
                            borderRadius: 7,
                            fontSize: 18,
                          }}
                        />
                      ) : (
                        <Button
                          htmlType="submit"
                          style={{
                            backgroundColor: "#416B88",
                            color: "white",
                            height: "44px",
                            width: "100px",
                            borderRadius: "8px",
                            fontWeight: 600,
                            fontSize: "18px",
                            padding: "6px 20px",
                          }}
                          loading={loading}
                        >
                          Save
                        </Button>
                      )}
                    </div>
                  </Form.Item>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    )}
    </>
  );
}
export default CompanyDetailFill;
