import React, { Component } from "react";
import {
  Layout,
  Button,
  Space,
  Dropdown,
  Menu,
  Popconfirm,
  Typography,
} from "antd";
import {
  //BellOutlined,
  //QuestionCircleOutlined,
  UserOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Link, withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import {
  setAuthor,
  setPermission,
  setFirstTimeUser,
  setCompanyName,
  setAccountDetails,
} from "../store/action/loginAction";
import { SliderMenu } from "../utility/context/Menu";
import XaaSLogo from "../assets/XaaSLogo.svg";
import SubMenu from "antd/lib/menu/SubMenu";
import DropDownMenu from "../common/DropDownMenu";
import {
  logout,
  setViewType,
  updateCompanyAccount,
} from "../store/action/loginAction";
import Title from "antd/lib/typography/Title";
import PlanIntroductionModel from "../common/PlanIntroductionModel";
import "./Layout.css";
import UpgradePlanModal from "../common/UpgradePlanModal";
import moment from "moment";
import Tour from "reactour";
import Paragraph from "antd/lib/typography/Paragraph";
import bulbIcon from "../assets/bulbIcon.svg";
import sdk from "../sdk/Accounts";

const { Header, Sider, Content } = Layout;
class MainLayout extends Component {
  constructor(props) {
    super(props);
    const location = this.props.location.pathname;
    const tieringAccess = {
      dashboard: {
        enable: true,
      },
      analytics: {
        enable: true,
      },
      billing: {
        enable: true,
      },
      users: {
        enable: true,
      },
      mydashboard: {
        enable: true,
      },
      support: {
        enable: true,
      },
      graphdefinitions: {
        enable: true,
      },
      companyprofile: {
        enable: true,
      },
    };
    this.state = {
      selectedKey: SliderMenu.filter((menus) => menus.path === location)[0]
        ? SliderMenu.filter((menus) => menus.path === location)[0].key
        : "",
      tieringAccess: tieringAccess,
      settingsMenuOpen: false,
      sliderMenu: [],
      analyticsMenu: [],
      superAccess: false,
      isTourOpen: false,
      c: 1,
      isTourClose: false,
      accountMetaData: {},
    };
  }

  componentWillMount = async () => {
    this.unlisten = this.props.history.listen((location, action) => {
      var key = SliderMenu.filter((menus) => menus.path === location.pathname);
      try {
        key && key.length > 0
          ? this.handleChangKey(key[0].key)
          : this.handleChangKey("");
      } catch (e) {}
    });
    if (this.props?.groups?.includes("author")) {
      let data = SliderMenu.filter((item) => item.title !== "Dashboard");
      this.setState({ sliderMenu: data });
    } else {
      this.setState({ sliderMenu: SliderMenu });
    }
    if (SliderMenu.filter((item) => item.title === "Analytics")) {
      let data = SliderMenu.filter((item) => item.title === "Analytics");
      this.setState({ analyticsMenu: data });
    } else {
      this.setState({ sliderMenu: SliderMenu });
    }
    if (
      window.location.pathname === "/" ||
      window.location.pathname === "/" ||
      window.location.pathname === "/details" ||
      window.location.pathname === "/settings" ||
      window.location.pathname === "/myprofile" ||
      window.location.pathname === "/company" ||
      window.location.pathname === "/admin"
    ) {
      if (this.props.groups?.includes("author")) {
        this.setState({
          superAccess: false,
        });
      }
    } else {
      if (this.props.groups?.includes("author")) {
        this.setState({
          superAccess: true,
        });
      }
    }
  };

  componentWillUnmount() {
    this.unlisten();
  }

  handleCollapsed = () => {
    if (!this.state.collapsed) {
      document.getElementById("site-layout").style.marginLeft = "80px";
    } else {
      document.getElementById("site-layout").style.marginLeft = "250px";
    }
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleChangKey = async (key) => {
    if (
      key === "analytics" ||
      key === "packaging" ||
      key === "pricingmodels" ||
      key === "pricinganddiscounting" ||
      window.location.pathname === "/analytics/packaging-models"
    ) {
      if (this.props.groups?.includes("author")) {
        this.setState({
          superAccess: true,
        });
      }
    } else {
      this.setState({
        superAccess: false,
      });
    }
    this.setState({
      selectedKey: key,
    });
  };

  handleSignOut = async () => {
    Auth.signOut({
      global: true,
    })
      .then((data) => {
        localStorage.clear();
        this.props.logOut();
      })
      .catch((err) => console.log(err));
  };

  switchAccountSelf = async (account) => {
    const { idToken } = await Auth.currentSession();
    await this.props.setPermission(idToken.payload);
    this.props.setViewType("admin");
    await this.props.updateCompanyAccount({ id: account[0] });
    let res = await sdk.fetchAccount(account[0]);
    this.props.setAccountDetails(res);
    this.props.setCompanyName(null);

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  handleClick = () => {
    this.props.setAuthor(false);
  };
  handleBack = () => {
    this.props.setAuthor(true);
  };

  render() {
    const { collapsed, selectedKey, tieringAccess } = this.state;
    const {
      groups,
      selectedCompanyName,
      admin_groups_self,
      viewType,
      accountDetails,
      firstTimeUser,
    } = this.props;
    return (
      <>
        <div>
          {/* other stuff */}
          <Tour
            accentColor={"#0F61DB"}
            steps={
              window.location.pathname === "/"
                ? [
                    steps[0],
                    steps[1],
                    steps[2],
                    steps[3],
                    steps[4],
                    steps[5],
                    steps[6],
                    steps[7],
                  ]
                : [
                    steps[0],
                    steps[1],
                    steps[2],
                    steps[3],
                    steps[4],
                    steps[5],
                  ] && window.location.pathname === "/details"
                ? [
                    steps[0],
                    steps[1],
                    steps[2],
                    steps[3],
                    steps[4],
                    steps[5],
                    steps[8],
                  ]
                : [steps[0], steps[1], steps[2], steps[3], steps[4], steps[5]]
            }
            isOpen={this.state.isTourOpen || firstTimeUser ? true : false}
            onRequestClose={() => {
              this.setState({ isTourOpen: false });
              this.props.setFirstTimeUser(false);
            }}
            rounded={5}
            scrollDuration={100}
            disableInteraction={true}
            onAfterOpen={(target) => (document.body.style.overflowY = "hidden")}
            onBeforeClose={(target) => (document.body.style.overflowY = "auto")}
            disableFocusLock={true}
            closeWithMask={false}
            // getCurrentStep={(curr) => this.setState({c : curr + 1})}
            startAt={0}
          />
        </div>

        <UpgradePlanModal
          visible={
            accountDetails?.accountStatus === "FREE_TRIAL_END" &&
            this.props.location.pathname !== "/settings" &&
            this.props.location.pathname !== "/support" &&
            this.props.location.pathname !== "/myprofile" &&
            this.props.location.pathname !== "/company" &&
            this.props.location.pathname !== "/" &&
            this.props.location.pathname !== "/graph-definition"
          }
          onContinue={() => {
            this.props.history.push("/settings");
          }}
        />
        <PlanIntroductionModel
          visible={
            !groups?.includes("admin") &&
            (accountDetails?.accountStatus === null ||
              accountDetails?.accountStatus === null) &&
            this.props.location.pathname !== "/details" &&
            this.props.location.pathname !== "/settings" &&
            this.props.location.pathname !== "/myprofile" &&
            this.props.location.pathname !== "/support" &&
            this.props.location.pathname !== "/graph-definition"
          }
          onContinue={() => {
            this.props.history.push("/settings");
          }}
        />
        <div id="menu-container" className="slidermenu">
          <Layout style={{ maxWidth: "100vw" }}>
            <Sider
              breakpoint="lg"
              collapsedWidth="0"
              onCollapse={(collapse, type) => {
                this.setState({ collapsed: collapse });
              }}
              width={250}
              style={{ height: "100vh", position: "fixed" }}
            >
              <div className="explorics-sider-menu">
                <div style={{ marginTop: "18px" }}>
                  <img
                    onClick={() => this.props.history.push("/")}
                    src={XaaSLogo}
                    alt=""
                    className="logo"
                    style={{
                      height: 100,
                      display: "block",
                      margin: "0 auto",
                      cursor: "pointer",
                    }}
                  />

                  <div>
                    <Menu
                      theme="dark"
                      mode="inline"
                      defaultSelectedKeys={selectedKey}
                      style={{ marginTop: "15px", background: "none" }}
                      onSelect={(p) => {
                        this.handleChangKey(p.key);
                      }}
                      selectedKeys={selectedKey}
                    >
                      {" "}
                      {this.state.sliderMenu.map((item, index) => {
                        const { key, title, icon, path, isSubMenu, tour } =
                          item;
                        return isSubMenu
                          ? tieringAccess?.[key]?.enable && (
                              <SubMenu
                                key={key}
                                className="subMenuItem"
                                icon={icon}
                                title={title}
                                onClick={() => this.handleChangKey(key)}
                                data-tour={tour}
                              >
                                {item.subMenu.map((subItem, index) => (
                                  <Menu.Item key={subItem.key}>
                                    <Link to={`${path}${subItem.path}`}>
                                      {" "}
                                      {subItem.title}
                                    </Link>
                                  </Menu.Item>
                                ))}
                              </SubMenu>
                            )
                          : tieringAccess?.[key]?.enable && (
                              <Menu.Item
                                key={key}
                                icon={icon}
                                id={key}
                                data-tour={tour}
                                // data-tour = "tour__billing"

                                style={{ background: "none" }}
                              >
                                <Link to={path}>{title}</Link>
                              </Menu.Item>
                            );
                      })}
                    </Menu>
                  </div>
                </div>

                {!collapsed && (
                  <div className="version">
                    <h2
                      style={{
                        color: "#799FB9",
                        fontSize: "18px",
                        textAlign: "center",
                        fontWeight: "600",
                        userSelect: "none",
                      }}
                    >
                      Version{" "}
                      {process.env.REACT_APP_VERSION
                        ? process.env.REACT_APP_VERSION
                        : "0.1"}
                    </h2>
                    <p
                      style={{
                        fontSize: "16px",
                        color: "#799FB9",
                        textAlign: "center",
                        bottom: "0px",
                        fontWeight: 600,
                        marginBottom: "0px",
                      }}
                    >
                      ©{`${moment().year()}`} XaaS Pricing All rights reserved{" "}
                      <a
                        style={{ color: "#799FB9" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.xaaspricing.com/terms-of-use/"
                      >
                        Terms of Service
                      </a>
                    </p>
                  </div>
                )}
              </div>
            </Sider>

            <Layout
              id="site-layout"
              className={!collapsed ? "layout" : "layoutCollapsed"}
            >
              <Header className="site-layout-background" style={{ padding: 0 }}>
                {window.location.pathname === "/graph-definition" && (
                  <Typography.Text className="text-desc">
                    User Guide To Metrics Used In All Graphs
                  </Typography.Text>
                )}
                <Button
                  style={{
                    color: "#416B88",
                    fontSize: 16,
                    borderBottom: "1px solid #416B88",
                    borderRadius: 0,
                    padding: 0,
                    marginLeft: 30,
                    fontWeight: 500,
                    fontFamily: "Matter-bold",
                  }}
                  type="text"
                  className="btn-underline"
                  onClick={() => this.setState({ isTourOpen: true })}
                >
                  Take Tour
                </Button>
                <div
                  data-tour="tour__companySetting"
                  style={{ float: "right", paddingRight: "24px" }}
                >
                  <Space className="header-right">
                    {this.state.superAccess ? (
                      this.props.superAuthorAccess ? (
                        <Button
                          onClick={() => this.handleClick()}
                          style={{
                            height: "35px",
                            color: "#416B88",
                            borderColor: "#416B88",
                            fontWeight: 600,
                          }}
                        >
                          Super Authors Access
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.handleBack()}
                          style={{
                            height: "35px",
                            color: "#416B88",
                            borderColor: "#416B88",
                            fontWeight: 600,
                          }}
                        >
                          Back to Analytics
                        </Button>
                      )
                    ) : (
                      ""
                    )}

                    <h3
                      style={{
                        textTransform: "capitalize",
                        fontWeight: 600,
                        marginBottom: 0,
                        color: "black",
                        fontSize: 16,
                      }}
                    >
                      Hi,
                      {localStorage.getItem("given_name")}
                    </h3>
                    <Dropdown
                      overlay={
                        <DropDownMenu
                          handleSignOut={this.handleSignOut}
                          path={this.props?.location?.pathname}
                        />
                      }
                      trigger={["click"]}
                      placement={"bottomLeft"}
                    >
                      <Button
                        type="text"
                        size="large"
                        icon={<UserOutlined />}
                        id="settings-menu-trigger-btn"
                        data-tour="tour__settings"
                        onBlur={() => {
                          setTimeout(() => {
                            this.setState({
                              settingsMenuOpen: false,
                            });
                          }, 200);
                        }}
                        onFocus={() => {
                          this.setState({
                            settingsMenuOpen: true,
                          });
                        }}
                      />
                    </Dropdown>
                  </Space>
                </div>
                {groups && groups.includes("admin") && (
                  <>
                    {selectedCompanyName !== null && (
                      <span
                        style={{
                          float: "right",
                          paddingRight: 10,
                          zIndex: 999,
                          position: "relative",
                        }}
                      >
                        <strong className="back-to-admin-link">
                          Viewing as{" "}
                          <strong style={{ color: "#0F61DB" }}>
                            {selectedCompanyName
                              ? selectedCompanyName
                              : "isIndividual"}{" "}
                            <span style={{ color: "#464646" }}>
                              {viewType === "user"
                                ? "(user view)"
                                : "(admin view)"}
                            </span>
                          </strong>
                          <Popconfirm
                            className="switchDilog"
                            placement="bottomRight"
                            title={
                              <Title level={5}>
                                Do you want to switch to your profile?
                              </Title>
                            }
                            onConfirm={() =>
                              this.switchAccountSelf(admin_groups_self)
                            }
                            okText="Yes"
                            cancelText="No"
                            icon={""}
                            okType=""
                            okButtonProps={{
                              size: "middle",
                              height: "auto",
                              padding: "4px 15px",
                              background: "#416B88",
                              fontSize: 16,
                              borderColor: "#416B88",
                            }}
                            cancelButtonProps={{
                              size: "large",
                              type: "secondary",
                            }}
                          >
                            <strong
                              style={{
                                paddingLeft: 8,
                                color: "#A9A9A9",
                                cursor: "pointer",
                              }}
                            >
                              <CloseOutlined />
                            </strong>
                          </Popconfirm>{" "}
                        </strong>
                      </span>
                    )}
                  </>
                )}
              </Header>
              <Content
                className="mainLayout"
                style={{
                  margin: "24px 21px",
                }}
              >
                {this.props.children}
              </Content>
            </Layout>
          </Layout>
        </div>
      </>
    );
  }
}

const steps = [
  {
    selector: '[data-tour = "tour__companySetting"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          {" "}
          Access your account details and company settings from here{" "}
        </Paragraph>
      </div>
    ),
    position: "top",
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },

  {
    selector: '[data-tour = "tour__mydashboard"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          {" "}
          Access overall pricing insights, reports, analyst content, and your
          pricing strategy grade here
        </Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
  {
    selector: '[data-tour = "tour__analytics"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          {" "}
          Deep dive on pricing strategy metrics by topic and company
        </Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
  {
    selector: '[data-tour = "tour__billing"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          {" "}
          Manage and update your plan here
        </Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
  {
    selector: '[data-tour = "tour__support"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          {" "}
          Submit requests for help here
        </Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
  {
    selector: '[data-tour = "tour__graph"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          {" "}
          All definitions of Graphs are here
        </Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
  {
    selector: '[data-tour = "tour__insight"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          View detailed analytics and insights based on your selected plan
        </Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
  {
    selector: '[data-tour = "tour__websitegrader"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">
          Recommendations on how to improve you company's pricing
        </Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
  {
    selector: '[data-tour = "tour__companies"]',
    content: () => (
      <div>
        <img src={bulbIcon} alt="" />
        <Paragraph className="paragraph">Choose Companies to Analyze</Paragraph>
      </div>
    ),
    action: (node) => {
      node?.focus();
    },
    style: {
      backgroundColor: "#D2DCE3",
    },
  },
];

const mapStateToProps = (state, ownProps) => {
  const {
    groups,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    dashboardTourOpen,
    accountDetails,
    superAuthorAccess,
    userData,
    userName,
    firstTimeUser,
    isIndividual,
  } = state.loginReducer;
  return {
    prop: state.prop,
    groups,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    dashboardTourOpen,
    accountDetails,
    superAuthorAccess,
    userData,
    userName,
    firstTimeUser,
    isIndividual,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logOut: () => dispatch(logout()),
    updateCompanyAccount: (account) => dispatch(updateCompanyAccount(account)),
    setPermission: (permission) => dispatch(setPermission(permission)),
    setViewType: (viewType) => dispatch(setViewType(viewType)),
    setAuthor: (data) => dispatch(setAuthor(data)),
    setFirstTimeUser: (data) => dispatch(setFirstTimeUser(data)),
    setCompanyName: (data) => dispatch(setCompanyName(data)),
    setAccountDetails: (data) => dispatch(setAccountDetails(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainLayout)
);
