import { API, graphqlOperation } from "aws-amplify";
import {
  createAccountRequest,
  deleteAccountRequest,
  updateAccount,
} from "../graphql/mutations";
import { v4 as uuidv4 } from "uuid";
import { listAccounts } from "../graphql/queries";
import { getAccountCustom } from "../graphql/customQueries";


const createAccount = async (company, url) => {
  const path = `/accounts`;
  if(company === "isIndividual"){
    let data = {
      "company" : "",
      "url" : "",
      "isIndividual" :true,
    }
    try {
      var result = await API.post("XaaSPricingREST", path, {
        body: data,
      });
  
      return result;
    } catch (e) {
      console.log(e);
      return {
        error: e.response?.data?.error
          ? e.response?.data?.error
          : "Failed creating account",
      };
    }
  }
  else{
    try {
      var resultData = await API.post("XaaSPricingREST", path, {
        body: { company, url },
      });
  
      return resultData;
    } catch (e) {
      console.log(e);
      return {
        error: e.response?.data?.error
          ? e.response?.data?.error
          : "Failed creating account",
      };
    }
  }
};

const requestAccess = async (accountID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(createAccountRequest, {
        input: { id: uuidv4(), account: accountID },
      })
    );
    request = request.data.createAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const deleteRequest = async (requestID) => {
  try {
    let request = await API.graphql(
      graphqlOperation(deleteAccountRequest, {
        input: { id: requestID },
      })
    );
    request = request.data.deleteAccountRequest;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const approveRequest = async (id) => {
  const path = `/accounts/requests/${id}/approve`;
  try {
    var result = await API.post("XaaSPricingREST", path);
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed approving request" };
  }
};

const companySuspension = async (companyId, suspend) => {
  try {
    let request = await API.graphql(
      graphqlOperation(updateAccount, {
        input: {
          id: companyId,
          disabled: suspend,
        },
      })
    );
    request = request.data.updateAccount;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const companyUpdate = async (inputdata) => {
  const path = "/updateaccount";
  try {
    var result = await API.put("XaaSPricingREST", `${path}`, {
      body: {
        ...inputdata,
      },
    });
    return result;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed to Update the Data"] };
  }
};

const fetchAccount = async (accountID) => {
  try {
    let account = await API.graphql(
      graphqlOperation(getAccountCustom, { id: accountID })
    );
    return account.data.getAccount;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving account"] };
  }
};

const sendEmailInvitation = async (value) => {
  const path = `/accounts/invite`;
  let body = {};
  body.emails = value.emails;
  if (value.account_id) {
    body.account_id = value.account_id;
  }
  if (value.name) {
    body.name = value.name;
  }
  try {
    var result = await API.post("XaaSPricingREST", path, {
      body: body,
    });
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed sending invitation" };
  }
};

const getOrgCheckoutSession = async (stripePriceId, priceId, accountId) => {
  let url = `/billing/checkout/org?stripePriceId=${stripePriceId}&internalPriceId=${priceId}&accountId=${accountId}`;

  try {
    let result = await API.get("XaaSPricingREST", url);
    return result;
  } catch (e) {
    console.log("error retrieving data", e);
    return { error: "Failed to fetch session" };
  }
};

const getAllUsers = async (nextToken, filter = {}) => {
  try {
    let companyList;
    if (Object.keys(filter).length > 0) {
      companyList = await API.graphql(
        graphqlOperation(listAccounts, { nextToken: nextToken, filter: filter })
      );
    } else {
      companyList = await API.graphql(
        graphqlOperation(listAccounts, { limit: 15, nextToken: nextToken })
      );
    }
    return companyList;
  } catch (e) {
    console.log(e);
    return { errors: ["Failed retrieving Companies"] };
  }
};

const downloadData = async (table) => {
  const path = `/redshiftDataManager/getTableData?table=${table}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
  try {
    var result = await API.get("XaaSPricingREST", path, config);
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed downloading data" };
  }
};

const uplaodFile = async (file) => {
  const path = `/redshiftDataManager/get_pre_signed_upload_url?filename=${file}`;
  try {
    var result = await API.get("XaaSPricingREST", path);
    return result;
  } catch (e) {
    console.log(e);
    return { error: "Failed downloading data" };
  }
};

const uploadToS3 = async (data) => {
  const path = `/redshiftDataManager/load_file_from_s3_to_redshift`;
  try {
    var result = await API.post("XaaSPricingREST", path, {
      body: { data },
    });

    return result;
  } catch (e) {
    console.log(e);
    return {
      error: e.response?.data?.error
        ? e.response?.data?.error
        : "Failed to uplaod",
    };
  }
};

const companySegment = async (companyId, segment) => {
  try {
    let request = await API.graphql(
      graphqlOperation(updateAccount, {
        input: {
          id: companyId,
          company_segment: segment,
        },
      })
    );

    request = request.data.updateAccount;
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
};

const getCancelSubscription = async(id) => {
  
  const path = `/billing/cancelSubscriptionDetails?stripeSubscriptionID=${id}`;
  try {
    let request = await API.get("XaaSPricingREST", path);
    return request;
  } catch (e) {
    console.log(e);
    return { errors: ["The requested account does not exist."] };
  }
}

const getManagebilling = async(id) => {
  const path = `/billing/portal/org?accountId=${id}`;
  try{
    let request = await API.get("XaaSPricingREST",path);
    return request;
  }catch (e) {
    console.log('error retrieving data', e);
    return { error: 'Failed to fetch session' };
  }
}

const sdk = {
  createAccount,
  requestAccess,
  fetchAccount,
  approveRequest,
  companyUpdate,
  deleteRequest,
  companySuspension,
  sendEmailInvitation,
  getAllUsers,
  getOrgCheckoutSession,
  downloadData,
  uplaodFile,
  uploadToS3,
  companySegment,
  getCancelSubscription,
  getManagebilling
};

export default sdk;
