import React, { useEffect, useState } from "react";
import "./CompanyProfile.css";
import ProductIcon from "../../assets/productIcon.svg";
import { Table, Skeleton, Col, Empty } from "antd";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";

function PricingDiscountingModelProfile() {
  const [tableData, setTableData] = useState([]);
  const { id } = useParams();
  const [productName, setProductName] = useState("");
  const [TableLoading, setTableLoading] = useState(false);

  const getPackageModel = async () => {
    setTableLoading(true);
    let path = `/redshiftDataManager/getSpecificCompanyDetails?companyid=${id}`;
    await API.get("XaaSPricingREST", path)
      .then((res) => {
        let data = Object.keys(res?.products);
        setProductName(data[0]);
        let tempKey = Object.keys(res?.products);
        let temp = [];

        Object.values(res?.products[tempKey[0]]).map((item) => {
          if (item !== null) {
            Object.entries(item).map(([key, value]) => {
              if (value.length > 0) {
                if (value?.[0]?.["% discount for annual commitment"]) {
                  value.map((val) => {
                    val["edition"] = key;
                    return val;
                  });
                  value.sort((a, b) => a.edition.localeCompare(b.edition));
                  temp.push(value);
                }
              }
              return temp;
            });
          }
          return temp;
        });
        setTableData(temp);
        setTableLoading(false);
      })
      .catch((err) => {
        setTableLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPackageModel();
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      title: "Product Edition Name",
      dataIndex: "edition",
      key: "edition",
      width: "15%",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Published List Price (In $USD) - Pricing Model 1",
      dataIndex: "published list price (in $usd)",
      key: "list",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Published Annual List Price (In $USD) - Pricing Model 1",
      dataIndex: "published annual list price (in $usd)",
      key: "annual",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "% Discount For Annual Commitment",
      dataIndex: "% discount for annual commitment",
      key: "discount",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Pricing Multiple Over Previous Edition",
      dataIndex: "pricing multiple over previous edition",
      key: "multiple",
      render: (record) => <span className="data-size">{record}</span>,
    },
    {
      title: "Pricing Multiple Over Previous Edition - Annual",
      dataIndex: "pricing multiple over previous edition - annual",
      key: "pricing",
      render: (record) => <span className="data-size">{record}</span>,
    },
  ];

  return (
    <div className="main-product-overview-wrapper">
      {TableLoading ? (
        <div style={{ background: "#fff", padding: 20 }}>
          <Skeleton active paragraph={{ rows: 7 }} />{" "}
        </div>
      ) : tableData?.length > 0 ? (
        <>
          <div className="col-icon">
            <div>
              <img src={ProductIcon} alt="" />
            </div>
            <div className="col-text">
              <span className="text-wrapper-title">{productName}</span>
            </div>
          </div>
          <div className="table-wrapper">
            {tableData.map((dataSource, index) => {
              return (
                <Table
                  key={index}
                  className="table-css"
                  bordered
                  dataSource={dataSource}
                  columns={columns}
                  pagination={false}
                ></Table>
              );
            })}
          </div>
        </>
      ) : (
        <Col span={24}>
          <Empty className="empty-data" />
        </Col>
      )}
    </div>
  );
}

export default PricingDiscountingModelProfile;
