import React, { Component } from "react";
import Xaaslogo from "../../../assets/Xaaslogo.png";
import { Row, Col, Form, Input, Button, Alert, message } from "antd";
import "../user.css";
import Index from "./background";
import sdk from "../../../sdk/Accounts";
import Footer from "../../../common/footer";
import { emailPattern } from "../../../utility/constants";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  login,
  setAccountDetails,
  setFirstTimeUser,
  setLoginData,
} from "../../../store/action/loginAction";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      loading: false,
      feedback: props.history.location.state
        ? props.history.location.state.feedback
        : "",
      error: "",
      accountDetails: props,
      groups: props,
      admin_groups_self: props,
      groupValues: props,
    };
  }
  handleState = (name, value) => {
    this.setState({ [name]: value });
  };

  handleSubmitAccount = async () => {
    await sdk
      .createAccount("isIndividual")
      .then(async (res) => {
        if (res.error) {
        } else {
          message.success(`Account Created Successfully`, 5);
          this.props.history.push("/details");
          this.props.setFirstTimeUser(res);
          this.fetchuser();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  fetchuser = async () => {
    if (this.props.groupValues?.length === 0) {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        await cognitoUser.refreshSession(
          currentSession.refreshToken,
          async (err, session) => {
            if (session) {
              const { accessToken } = session;
              if ("cognito:groups" in accessToken.payload) {
                let userAccountId = [];
                userAccountId = accessToken.payload["cognito:groups"];
                userAccountId.push(
                  userAccountId.splice(userAccountId.indexOf("admin"), 1)[0]
                );
                clearInterval();
                if (userAccountId?.length > 0) {
                  let res = await sdk.fetchAccount(userAccountId[0]);
                  this.props.setAccountDetails(res);
                }
                await Auth.currentUserInfo().then((data) => {
                  this.props.setLoginData(session, data?.attributes);
                  clearInterval();
                });
              }
            }
          }
        );
      } catch (e) {
        console.log("Unable to refresh Token", e);
      }
    }
  };

  handleSubmit = (e) => {
    this.setState(
      {
        loading: true,
        error: "",
      },
      () => {
        this.props
          .loginData(e.userName, e.password)
          .then((response) => {
            Auth.currentAuthenticatedUser().catch((err) => console.log(err));

            if (response?.challengeName === "NEW_PASSWORD_REQUIRED") {
              this.props.history.push({
                pathname: `/create-password`,
                state: {
                  userAttributes: response?.challengeParam?.userAttributes,
                  oldPassword: e.password,
                },
              });
            }

            if (this.props.groupValues?.length === 0) {
              this.handleSubmitAccount();
            }

            this.setState(
              {
                loading: false,
              },

              () => this.props.history.push("/")
            );
          })
          .catch((error) => {
            if (error?.message === "User is not confirmed.") {
              this.props.history.push({
                pathname: `/confirm_email`,
                state: { feedback: "", userName: e.userName },
              });
            }
            console.log("error signing in:", error);
            this.setState({
              error: error.message,
              loading: false,
              userName: "",
              password: "",
            });
          });
      }
    );
  };

  componentDidMount() {
    const reloadCount = sessionStorage.getItem("reloadCount");
    if (reloadCount < 2) {
      sessionStorage.setItem("reloadCount", String(reloadCount + 1));
    } else {
      sessionStorage.removeItem("reloadCount");
    }
    // this.fetchuser()
  }

  render() {
    const { email, password, loading, error, feedback } = this.state;
    const { history } = this.props;
    return (
      <Row justify="center">
        <Index />
        <Col span={12} md={12} lg={12} xs={24}>
          <Row
            gutter={24}
            justify="center"
            style={{
              display: "flex",
              alignItems: "center",
              height: "100vh",
              width: "100%",
              position: "relative",
              margin: 0,
            }}
          >
            <Col
              span={12}
              md={24}
              lg={12}
              xs={24}
              sm={24}
              style={{ textAlign: "left" }}
            >
              <img
                src={Xaaslogo}
                alt=""
                style={{ display: "flex", margin: "0 auto", height: 100 }}
              />
              {feedback && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="success"
                  showIcon={false}
                  message={feedback}
                  banner
                />
              )}
              {error !== "" && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={error}
                  banner
                />
              )}
              <Form
                layout="vertical"
                name="normal_login"
                className="login-form"
                requiredMark={false}
                onFinish={(e) => this.handleSubmit(e)}
                style={{ marginTop: 30 }}
              >
                <Form.Item
                  label={
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: "#727272",
                      }}
                    >
                      Email
                    </span>
                  }
                  initialValue={email}
                  name="userName"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    value={email}
                    onChange={(e) =>
                      this.handleState(e.target.name, e.target.value)
                    }
                    id="userName"
                    key="userName"
                    name="userName"
                    style={{
                      borderRadius: "8px",
                      height: "44px",
                      border: "1px solid #CBCBCB",
                      fontSize: 18,
                      color: "#303030",
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span style={{ fontSize: 18, fontWeight: 600 }}>
                      Password
                    </span>
                  }
                  style={{ fontWeight: "bold", marginBottom: 0 }}
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    value={password}
                    id="password"
                    key="password"
                    name="password"
                    style={{
                      borderRadius: "8px",
                      height: "44px",
                      border: "1px solid #CBCBCB",
                      fontSize: 18,
                    }}
                  />
                </Form.Item>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={() => history.push("/forgotpassword")}
                    type="link"
                    disabled={loading}
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                      color: "#727272",
                      cursor: "pointer",
                      paddingBottom: 30,
                      visibility: "hidden",
                    }}
                  >
                    Reset Password
                  </Button>
                  <Button
                    onClick={() => history.push("/forgotpassword")}
                    type="link"
                    disabled={loading}
                    style={{
                      fontSize: 16,
                      textAlign: "right",
                      color: "#727272",
                      cursor: "pointer",
                      marginBottom: 20,
                      fontWeight: 400,
                      paddingRight: 0,
                    }}
                  >
                    Forgot password?
                  </Button>
                </div>
                <Form.Item>
                  <Button
                    size="large"
                    htmlType="submit"
                    block
                    loading={loading}
                    tabIndex={5}
                    style={{
                      background: "#416B88",
                      color: "#FFFFFF",
                      borderRadius: "8px",
                      height: "44px",
                      marginBottom: 0,
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                  >
                    <span>Login</span>
                  </Button>
                </Form.Item>
                <span
                  style={{
                    fontSize: 16,
                    textAlign: "center",
                    color: "#727272",
                    paddingBottom: 30,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 400,
                  }}
                >
                  Don't have an Account?{" "}
                  <span
                    style={{
                      color: "#416B88",
                      paddingLeft: 3,
                      fontWeight: 600,
                    }}
                    onClick={() => history.push("/signup")}
                  >
                    SIGN UP
                  </span>
                </span>
              </Form>
            </Col>
            <Footer />
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    groups,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    dashboardTourOpen,
    accountDetails,
    superAuthorAccess,
    userData,
    userName,
    firstTimeUser,
    groupValues,
  } = state.loginReducer;

  return {
    prop: state.prop,
    groups,
    groupValues,
    selectedCompanyName,
    admin_groups_self,
    userPermission,
    viewType,
    dashboardTourOpen,
    accountDetails,
    superAuthorAccess,
    userData,
    userName,
    firstTimeUser,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginData: (userName, password) => dispatch(login(userName, password)),
    setLoginData: (token,data) => dispatch(setLoginData(token,data)),
    setAccountDetails: (data) => dispatch(setAccountDetails(data)),
    setFirstTimeUser: (data) => dispatch(setFirstTimeUser(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
