import React from "react";
import { Button, Image, Modal, Typography, Row, Col } from "antd";
import "./PlanModel.css";
import PlanIntroductionModelImg from "../assets/introductionmodel.svg";

const PlanIntroductionModel = ({ visible, onContinue }) => {
  return (
    <>
      <Modal
        className="planIntroModal"
        centered
        footer={null}
        title={null}
        visible={visible}
      >
        <div
          style={{ display: "flex", justifyContent: "center", paddingTop: 30 }}
        >
          <Image
            width={134}
            height={80}
            preview={false}
            src={PlanIntroductionModelImg}
          />
        </div>
        <div>
          <Typography.Text
            style={{
              fontSize: 18,
              display: "flex",
              justifyContent: "center",
              paddingTop: 10,
              fontWeight: 600,
            }}
          >
            Please select the plan to continue
          </Typography.Text>
          <Typography.Text
           style={{
            fontSize: 14,
            display: "flex",
            justifyContent: "center",
            fontWeight: 600,
          }}>
                   Trial version available
          </Typography.Text>
            
        </div>
        <Row justify="center">
          <Col style={{ display: "flex", paddingTop: 10 }}>
            <Button
              style={{
                height: "auto",
                background: "#416B88",
                width: "auto",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                fontWeight: 600,
                padding: "9px 14px",
                marginBottom: 10
              }}
              onClick={() => {
                onContinue?.();
              }}
              className="planIntroModal_continueBtn"
            >
              Continue
            </Button>
            {/* <Button
              style={{
                height: "auto",
                background: "#416B88",
                width: "auto",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                fontWeight: 600,
                padding: "9px 14px",
                marginLeft: 10,
              }}
              onClick={() => {
                onContinue?.();
              }}
              className="planIntroModal_continueBtn"
            >
              Cancle
            </Button> */}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PlanIntroductionModel;
